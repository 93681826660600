// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import reportGeneratorActions from './actions';
import siteConfig from '@iso/config/site.config';
import { db } from '@iso/lib/firebase/firebase';

export const onReportRequest = async (request) =>
  await fetch(
    `${siteConfig.apiUrl}/${
      localStorage.getItem('userRole') === 'marketplace'
        ? 'reportgeneratorMarketplace'
        : 'reportgenerator'
    }`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(request),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.orders;
    });

export const onReportCurrentRequest = async (request) =>
  await fetch(
    `${siteConfig.apiUrl}/${
      localStorage.getItem('userRole') === 'marketplace'
        ? 'reportgeneratorMarketplace'
        : 'reportgenerator'
    }`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(request),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.orders;
    });

export const onReportYesterdayRequest = async (request) =>
  await fetch(
    `${siteConfig.apiUrl}/${
      localStorage.getItem('userRole') === 'marketplace'
        ? 'reportgeneratorMarketplace'
        : 'reportgenerator'
    }`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(request),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json.orders;
    });

export const onFinReportRequest = async (restaurantId) =>
  await fetch(`${siteConfig.apiUrl}/generateFinancialReport/${restaurantId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onFinReportFiltersRequest = async (restaurantId, filters) =>
  await fetch(`${siteConfig.apiUrl}/generateFinancialReport/${restaurantId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(filters),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchReportEffect({ payload }) {
  try {
    let report;
    if (payload.date_start) {
      const date_start = new Date(
        new Date(payload.date_start[0]).getTime()
      ).toISOString();
      const date_end = new Date(
        new Date(payload.date_start[1]).getTime()
      ).toISOString();

      const orderFilters = {
        ...payload,
        date_start: [date_start, date_end],
      };
      report = yield call(onReportRequest, orderFilters);
    } else {
      report = yield call(onReportRequest, payload);
    }
    yield put(reportGeneratorActions.fetchReportSuccess(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchReportFailure(error));
  }
}

function* fetchReportCurrentEffect({ payload }) {
  try {
    let report;
    if (payload.date_start) {
      const date_start = new Date(
        new Date(payload.date_start[0]).getTime()
      ).toISOString();
      const date_end = new Date(
        new Date(payload.date_start[1]).getTime()
      ).toISOString();

      const orderFilters = {
        ...payload,
        date_start: [date_start, date_end],
      };
      report = yield call(onReportCurrentRequest, orderFilters);
    } else {
      report = yield call(onReportCurrentRequest, payload);
    }
    yield put(reportGeneratorActions.fetchReportCurrentSuccess(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchReportCurrentFailure(error));
  }
}

function* fetchReportYesterdayEffect({ payload }) {
  try {
    let report;
    if (payload.date_start) {
      const date_start = new Date(
        new Date(payload.date_start[0]).getTime()
      ).toISOString();
      const date_end = new Date(
        new Date(payload.date_start[1]).getTime()
      ).toISOString();

      const orderFilters = {
        ...payload,
        date_start: [date_start, date_end],
      };
      report = yield call(onReportYesterdayRequest, orderFilters);
    } else {
      report = yield call(onReportYesterdayRequest, payload);
    }
    yield put(reportGeneratorActions.fetchReportYesterdaySuccess(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchReportYesterdayFailure(error));
  }
}

function* fetchFinReportEffect({ payload }) {
  try {
    let report = yield call(onFinReportRequest, payload);
    yield put(reportGeneratorActions.fetchFinReportSuccess(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchFinReportFailure(error));
  }
}

function* fetchFinReportFiltersEffect({ payload }) {
  try {
    let report;
    if (payload.date_start) {
      const date_start = new Date(
        new Date(payload?.date_start[0]).getTime()
      ).toISOString();
      const date_end = new Date(
        new Date(payload?.date_start[1]).getTime()
      ).toISOString();

      const orderFilters = {
        ...payload,
        date_start: [date_start, date_end],
      };
      report = yield call(
        onFinReportFiltersRequest,
        payload.restaurant_id[0],
        orderFilters
      );
    } else {
      report = yield call(
        onFinReportFiltersRequest,
        payload.restaurant_id[0],
        payload
      );
    }
    yield put(reportGeneratorActions.fetchFinReportSuccess(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchFinReportFailure(error));
  }
}

function* fetchFinReportFiltersEffectCurrent({ payload }) {
  try {
    let report;
    if (payload.date_start) {
      const date_start = new Date(
        new Date(payload?.date_start[0]).getTime()
      ).toISOString();
      const date_end = new Date(
        new Date(payload?.date_start[1]).getTime()
      ).toISOString();

      const orderFilters = {
        ...payload,
        date_start: [date_start, date_end],
      };
      report = yield call(
        onFinReportFiltersRequest,
        payload.restaurant_id[0],
        orderFilters
      );
    } else {
      report = yield call(
        onFinReportFiltersRequest,
        payload.restaurant_id[0],
        payload
      );
    }
    yield put(reportGeneratorActions.fetchFinReportSuccessCurrent(report));
  } catch (error) {
    yield put(reportGeneratorActions.fetchFinReportFailure(error));
  }
}

export default function* reportGeneratorSaga() {
  yield all([
    takeEvery(reportGeneratorActions.FETCH_REPORT_START, fetchReportEffect),
  ]);
  yield all([
    takeEvery(
      reportGeneratorActions.FETCH_REPORT_CURRENT_START,
      fetchReportCurrentEffect
    ),
  ]);
  yield all([
    takeEvery(
      reportGeneratorActions.FETCH_REPORT_YESTERDAY_START,
      fetchReportYesterdayEffect
    ),
  ]);
  yield all([
    takeEvery(
      reportGeneratorActions.FETCH_FIN_REPORT_START,
      fetchFinReportEffect
    ),
  ]);
  yield all([
    takeEvery(
      reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START,
      fetchFinReportFiltersEffect
    ),
  ]);
  yield all([
    takeEvery(
      reportGeneratorActions.FETCH_FIN_REPORT_FILTERS_START_CURRENT,
      fetchFinReportFiltersEffectCurrent
    ),
  ]);
}
