import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import todosSagas from '@iso/redux/todos/saga';
import profileSaga from '@iso/redux/profile/saga';
import partnersSaga from '@iso/redux/partners/saga';
import trainingSaga from '@iso/redux/training/saga';
import stopListsSaga from '@iso/redux/stoplists/saga';
import menuSaga from '@iso/redux/menu/saga';
import questionnairesSagas from '@iso/redux/questionnaireForm/saga';
import RestaurantOrdersSagas from '@iso/redux/orders/saga';
import restaurantSaga from '@iso/redux/restaurant/saga';
import checklistSaga from '@iso/redux/checklist/saga';
import reportGeneratorSaga from '@iso/redux/reportgenerator/saga';
import accountSettingsSaga from '@iso/redux/accountSettings/saga';
import LocalEatsSaga from '@iso/redux/localEats/saga';
import DeliverySaga from '@iso/redux/delivery/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todosSagas(),
    profileSaga(),
    partnersSaga(),
    trainingSaga(),
    stopListsSaga(),
    menuSaga(),
    questionnairesSagas(),
    RestaurantOrdersSagas(),
    restaurantSaga(),
    reportGeneratorSaga(),
    checklistSaga(),
    accountSettingsSaga(),
    LocalEatsSaga(),
    DeliverySaga(),
  ]);
}
