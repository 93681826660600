import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';

const WDLine = styled.div`
  .line {
    stroke: #5f9ea0;
  }

  #svg-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

const marginMenu = {
  top: '35%',
  bottom: 0,
  right: 0,
  left: '3%',
  backgroundColor: 'rgb(231,63,64)',
  width: '14%',
  height: '7%',
  color: '#ffffff',
  position: 'absolute',
  whiteSpace: 'normal',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginCooking = {
  top: '45%',
  bottom: 0,
  right: 0,
  left: '3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '14%',
  height: '7%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginProductList = {
  top: '54%',
  bottom: 0,
  right: 0,
  left: '3%',
  backgroundColor: 'rgb(234,150,51)',
  width: '14%',
  height: '11%',
  wordWrap: 'break-word',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginFoodSafety = {
  top: '67%',
  bottom: 0,
  right: 0,
  left: '3%',
  backgroundColor: 'rgb(163,200,61)',
  width: '14%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginPackingList = {
  top: '77%',
  bottom: 0,
  right: 0,
  left: '3%',
  backgroundColor: 'rgb(154,187,232)',
  width: '14%',
  height: '7%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

//----------------------- second col --------------------------------------------------

export const marginSuppliers = {
  top: '54%',
  bottom: 0,
  right: 0,
  left: '22.3%',
  backgroundColor: 'rgb(234,150,51)',
  width: '14%',
  height: '11%',
  wordWrap: 'break-word',

  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginFoodKBZHY = {
  top: '67%',
  bottom: 0,
  right: 0,
  left: '22.3%',
  backgroundColor: 'rgb(163,200,61)',
  width: '14%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '1vw',
  fontWeight: '400',
};

export const marginPackingListVideo = {
  top: '77%',
  bottom: 0,
  right: 0,
  left: '22.3%',
  backgroundColor: 'rgb(154,187,232)',
  width: '14%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

//----------------------- third col --------------------------------------------------

export const marginFromPoint = {
  bottom: 0,
  right: 0,
  left: '35%',
  width: 0,
  height: 0,
  position: 'absolute',
  whiteSpace: 'normal',
};

export const marginProcessMap = {
  zIndex: 1,
  top: '14%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '7%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginCookbook = {
  zIndex: 1,
  top: '22%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginMenuPosters = {
  zIndex: 1,
  top: '28%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginBlankSheet = {
  zIndex: 1,
  top: '34%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginEquipment = {
  zIndex: 1,
  top: '40%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginTrainingVideos = {
  zIndex: 1,
  top: '46%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginStorageConditions = {
  top: '67%',
  bottom: 0,
  right: 0,
  left: '41.3%',
  backgroundColor: 'rgb(163,200,61)',
  width: '16%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

//----------------------- fourth col --------------------------------------------------

export const marginFromPointFourth = {
  bottom: 0,
  right: 0,
  left: '59%',
  width: 0,
  height: 0,
  position: 'absolute',
  whiteSpace: 'normal',
};

export const marginSemiFinishedProduct = {
  top: '37%',
  bottom: 0,
  right: 0,
  left: '62%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginAssemblingDishes = {
  top: '55%',
  bottom: 0,
  right: 0,
  left: '62%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '5%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginTemperatureRegime = {
  top: '67%',
  bottom: 0,
  right: 0,
  left: '62%',
  backgroundColor: 'rgb(163,200,61)',
  width: '16%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

//----------------------- fifth col --------------------------------------------------

export const marginFromPointFifth = {
  bottom: 0,
  right: 0,
  left: '79.5%',
  width: 0,
  height: 0,
  position: 'absolute',
  whiteSpace: 'normal',
};

export const marginSauces = {
  top: '31%',
  bottom: 0,
  right: '2%',
  left: '82%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '4%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginVideo = {
  top: '37.5%',
  bottom: 0,
  right: '2%',
  left: '82%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '4%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginOther = {
  top: '44%',
  bottom: 0,
  right: '2%',
  left: '82%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '4%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginMainDishes = {
  top: '52%',
  bottom: 0,
  right: '2%',
  left: '82%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '4%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginSemiOther = {
  top: '59%',
  bottom: 0,
  right: '2%',
  left: '82%',
  backgroundColor: 'rgb(227,193,59)',
  width: '16%',
  height: '4%',
  position: 'absolute',
  whiteSpace: 'normal',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export const marginMarking = {
  top: '67%',
  bottom: 0,
  right: 0,
  left: '82%',
  backgroundColor: 'rgb(163,200,61)',
  width: '16%',
  height: '7%',
  whiteSpace: 'normal',
  position: 'absolute',
  color: '#000000',
  borderColor: '#807777',
  fontSize: '0.9vw',
  fontWeight: '400',
};

export default marginMenu;

const LineBtn = WithDirection(WDLine);
export { LineBtn };
