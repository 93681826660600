const actions = {
  FETCH_POSTS_ORDER_DELIVERY_COST_START:
    'FETCH_POSTS_ORDER_DELIVERY_COST_START',
  FETCH_POSTS_ORDER_DELIVERY_COST_SUCCESS:
    'FETCH_POSTS_ORDER_DELIVERY_COST_SUCCESS',
  FETCH_POSTS_ORDER_DELIVERY_COST_FAILURE:
    'FETCH_POSTS_ORDER_DELIVERY_COST_FAILURE',

  fetchPostsOrderDeliveryCostStart: (orderId) => ({
    type: actions.FETCH_POSTS_ORDER_DELIVERY_COST_START,
    orderId,
  }),
  fetchPostsOrderDeliveryCostSuccess: (activeOrders) => ({
    type: actions.FETCH_POSTS_ORDER_DELIVERY_COST_SUCCESS,
    payload: activeOrders,
  }),
  fetchPostsOrderDeliveryCostFailure: (error) => ({
    type: actions.FETCH_POSTS_ORDER_DELIVERY_COST_FAILURE,
    payload: error,
  }),
};
export default actions;
