const restaurantActions = {
  FETCH_RESTAURANTS_START: 'FETCH_RESTAURANTS_START',
  FETCH_RESTAURANTS_SUCCESS: 'FETCH_RESTAURANTS_SUCCESS',
  FETCH_RESTAURANTS_FAILURE: 'FETCH_RESTAURANTS_FAILURE',

  GET_RESTAURANT_STATE: 'GET_RESTAURANT_STATE',
  GET_RESTAURANT_STATE_SUCCESS: 'GET_RESTAURANT_STATE_SUCCESS',
  GET_RESTAURANT_STATE_FAILURE: 'GET_RESTAURANT_STATE_FAILURE',

  SET_RESTAURANT_BLOCK: 'SET_RESTAURANT_BLOCK',
  SET_RESTAURANT_BLOCK_SUCCESS: 'SET_RESTAURANT_BLOCK_SUCCESS',
  SET_RESTAURANT_BLOCK_FAILURE: 'SET_RESTAURANT_BLOCK_FAILURE',
  SET_RESTAURANT_UNBLOCK: 'SET_RESTAURANT_UNBLOCK',
  SET_RESTAURANT_UNBLOCK_SUCCESS: 'SET_RESTAURANT_UNBLOCK_SUCCESS',
  SET_RESTAURANT_UNBLOCK_FAILURE: 'SET_RESTAURANT_UNBLOCK_FAILURE',

  FETCH_RESTAURANTS_WL_START: 'FETCH_RESTAURANTS_WL_START',
  FETCH_RESTAURANTS_WL_SUCCESS: 'FETCH_RESTAURANTS_WL_SUCCESS',
  FETCH_RESTAURANTS_WL_FAILURE: 'FETCH_RESTAURANTS_WL_FAILURE',

  FETCH_RESTAURANT_TABLES_START: 'FETCH_RESTAURANT_TABLES_START',
  FETCH_RESTAURANT_TABLES_SUCCESS: 'FETCH_RESTAURANT_TABLES_SUCCESS',
  FETCH_RESTAURANT_TABLES_FAILURE: 'FETCH_RESTAURANT_TABLES_FAILURE',

  FETCH_ADD_RESTAURANT_TABLE_START: 'FETCH_ADD_RESTAURANT_TABLE_START',
  FETCH_ADD_RESTAURANT_TABLES_SUCCESS: 'FETCH_ADD_RESTAURANT_TABLES_SUCCESS',
  FETCH_ADD_RESTAURANT_TABLES_FAILURE: 'FETCH_ADD_RESTAURANT_TABLES_FAILURE',

  FETCH_RESTAURANT_WAITERS_START: 'FETCH_RESTAURANT_WAITERS_START',
  FETCH_RESTAURANT_WAITERS_SUCCESS: 'FETCH_RESTAURANT_WAITERS_SUCCESS',
  FETCH_RESTAURANT_WAITERS_FAILURE: 'FETCH_RESTAURANT_WAITERS_FAILURE',

  FETCH_ADD_RESTAURANT_WAITER_START: 'FETCH_ADD_RESTAURANT_WAITER_START',
  FETCH_ADD_RESTAURANT_WAITER_SUCCESS: 'FETCH_ADD_RESTAURANT_WAITER_SUCCESS',
  FETCH_ADD_RESTAURANT_WAITER_FAILURE: 'FETCH_ADD_RESTAURANT_WAITER_FAILURE',

  FETCH_ADD_WAITER_TO_TABLE_START: 'FETCH_ADD_WAITER_TO_TABLE_START',
  FETCH_ADD_WAITER_TO_TABLE_SUCCESS: 'FETCH_ADD_WAITER_TO_TABLE_SUCCESS',
  FETCH_ADD_WAITER_TO_TABLE_FAILURE: 'FETCH_ADD_WAITER_TO_TABLE_FAILURE',

  FETCH_DELETE_WAITER_FROM_TABLE_START: 'FETCH_DELETE_WAITER_FROM_TABLE_START',
  FETCH_DELETE_WAITER_FROM_TABLE_SUCCESS:
    'FETCH_DELETE_WAITER_FROM_TABLE_SUCCESS',
  FETCH_DELETE_WAITER_FROM_TABLE_FAILURE:
    'FETCH_DELETE_WAITER_FROM_TABLE_FAILURE',

  FETCH_DELETE_RESTAURANT_TABLE_START: 'FETCH_DELETE_RESTAURANT_TABLE_START',
  FETCH_DELETE_RESTAURANT_TABLE_SUCCESS:
    'FETCH_DELETE_RESTAURANT_TABLE_SUCCESS',
  FETCH_DELETE_RESTAURANT_TABLE_FAILURE:
    'FETCH_DELETE_RESTAURANT_TABLE_FAILURE',

  FETCH_DELETE_RESTAURANT_WAITER_START: 'FETCH_DELETE_RESTAURANT_WAITER_START',
  FETCH_DELETE_RESTAURANT_WAITER_SUCCESS:
    'FETCH_DELETE_RESTAURANT_WAITER_SUCCESS',
  FETCH_DELETE_RESTAURANT_WAITER_FAILURE:
    'FETCH_DELETE_RESTAURANT_WAITER_FAILURE',

  FETCH_RESTAURANT_RATING_START: 'FETCH_RESTAURANT_RATING_START',
  FETCH_RESTAURANT_RATING_SUCCESS: 'FETCH_RESTAURANT_RATING_SUCCESS',
  FETCH_RESTAURANT_RATING_FAILURE: 'FETCH_RESTAURANT_RATING_FAILURE',

  FETCH_RESTAURANT_MANAGERS_START: 'FETCH_RESTAURANT_MANAGERS_START',
  FETCH_RESTAURANT_MANAGERS_SUCCESS: 'FETCH_RESTAURANT_MANAGERS_SUCCESS',
  FETCH_RESTAURANT_MANAGERS_FAILURE: 'FETCH_RESTAURANT_MANAGERS_FAILURE',

  FETCH_ADD_RESTAURANT_MANAGERS_START: 'FETCH_ADD_RESTAURANT_MANAGERS_START',
  FETCH_ADD_RESTAURANT_MANAGERS_SUCCESS:
    'FETCH_ADD_RESTAURANT_MANAGERS_SUCCESS',
  FETCH_ADD_RESTAURANT_MANAGERS_FAILURE:
    'FETCH_ADD_RESTAURANT_MANAGERS_FAILURE',

  FETCH_DELETE_RESTAURANT_MANAGER_START:
    'FETCH_DELETE_RESTAURANT_MANAGER_START',
  FETCH_DELETE_RESTAURANT_MANAGER_SUCCESS:
    'FETCH_DELETE_RESTAURANT_MANAGER_SUCCESS',
  FETCH_DELETE_RESTAURANT_MANAGER_FAILURE:
    'FETCH_DELETE_RESTAURANT_MANAGER_FAILURE',

  FETCH_ADD_MANAGER_TO_TABLE_START: 'FETCH_ADD_MANAGER_TO_TABLE_START',
  FETCH_ADD_MANAGER_TO_TABLE_SUCCESS: 'FETCH_ADD_MANAGER_TO_TABLE_SUCCESS',
  FETCH_ADD_MANAGER_TO_TABLE_FAILURE: 'FETCH_ADD_MANAGER_TO_TABLE_FAILURE',

  FETCH_DELETE_MANAGER_FROM_TABLE_START:
    'FETCH_DELETE_MANAGER_FROM_TABLE_START',
  FETCH_DELETE_MANAGER_FROM_TABLE_SUCCESS:
    'FETCH_DELETE_MANAGER_FROM_TABLE_SUCCESS',
  FETCH_DELETE_MANAGER_FROM_TABLE_FAILURE:
    'FETCH_DELETE_MANAGER_FROM_TABLE_FAILURE',
  UNSUBSCRIBE_CUSTOMER: 'UNSUBSCRIBE_CUSTOMER',
  UNSUBSCRIBE_CUSTOMER_SUCCESS: 'UNSUBSCRIBE_CUSTOMER_SUCCESS',
  FETCH_BRANDS: 'FETCH_BRANDS',
  FETCH_RESTAURANT_BRANDS_SUCCESS: 'FETCH_RESTAURANT_BRANDS_SUCCESS',
  FETCH_RESTAURANT_BRANDS_FAILURE: 'FETCH_RESTAURANT_BRANDS_FAILURE',
  SWITCH_BRAND_TEMPLATE: 'SWITCH_BRAND_TEMPLATE',

  fetchRestaurantsStart: () => ({
    type: restaurantActions.FETCH_RESTAURANTS_START,
  }),
  fetchRestaurantsSuccess: (restaurants) => ({
    type: restaurantActions.FETCH_RESTAURANTS_SUCCESS,
    payload: restaurants,
  }),
  fetchRestaurantsFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANTS_FAILURE,
    payload: error,
  }),

  getRestaurantState: (data) => ({
    type: restaurantActions.GET_RESTAURANT_STATE,
    payload: data,
  }),
  getRestaurantStateSuccess: (data) => ({
    type: restaurantActions.GET_RESTAURANT_STATE_SUCCESS,
    payload: data,
  }),
  getRestaurantStateFailure: (error) => ({
    type: restaurantActions.GET_RESTAURANT_STATE_FAILURE,
    payload: error,
  }),

  setRestaurantUnblock: (data) => ({
    type: restaurantActions.SET_RESTAURANT_UNBLOCK,
    payload: { data },
  }),
  setRestaurantUnblockSuccess: (data) => ({
    type: restaurantActions.SET_RESTAURANT_UNBLOCK_SUCCESS,
    payload: { data },
  }),
  setRestaurantUnblockFailure: (error) => ({
    type: restaurantActions.SET_RESTAURANT_UNBLOCK_FAILURE,
    payload: error,
  }),

  setRestaurantBlock: (data) => ({
    type: restaurantActions.SET_RESTAURANT_BLOCK,
    payload: { data },
  }),
  setRestaurantBlockSuccess: (data) => ({
    type: restaurantActions.SET_RESTAURANT_BLOCK_SUCCESS,
    payload: { data },
  }),
  setRestaurantBlockFailure: (error) => ({
    type: restaurantActions.SET_RESTAURANT_BLOCK_FAILURE,
    payload: error,
  }),

  fetchRestaurantsWLStart: () => ({
    type: restaurantActions.FETCH_RESTAURANTS_WL_START,
  }),
  fetchRestaurantsWLSuccess: (restaurants) => ({
    type: restaurantActions.FETCH_RESTAURANTS_WL_SUCCESS,
    payload: restaurants,
  }),
  fetchRestaurantsWLFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANTS_WL_FAILURE,
    payload: error,
  }),

  fetchRestaurantTablesStart: (restaurantId) => ({
    type: restaurantActions.FETCH_RESTAURANT_TABLES_START,
    payload: restaurantId,
  }),
  fetchRestaurantTablesSuccess: (restaurants) => ({
    type: restaurantActions.FETCH_RESTAURANT_TABLES_SUCCESS,
    payload: restaurants,
  }),
  fetchRestaurantTablesFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANT_TABLES_FAILURE,
    payload: error,
  }),

  fetchAddRestaurantTableStart: (table) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_TABLE_START,
    payload: table,
  }),
  fetchAddRestaurantTableSuccess: (newTable) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_TABLES_SUCCESS,
    payload: newTable,
  }),
  fetchAddRestaurantTableFailure: (error) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_TABLES_FAILURE,
    payload: error,
  }),

  fetchRestaurantWaitersStart: (restaurantId) => ({
    type: restaurantActions.FETCH_RESTAURANT_WAITERS_START,
    payload: restaurantId,
  }),
  fetchRestaurantWaitersSuccess: (restaurants) => ({
    type: restaurantActions.FETCH_RESTAURANT_WAITERS_SUCCESS,
    payload: restaurants,
  }),
  fetchRestaurantWaitersFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANT_WAITERS_FAILURE,
    payload: error,
  }),

  fetchAddRestaurantWaiterStart: (table) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_WAITER_START,
    payload: table,
  }),
  fetchAddRestaurantWaiterSuccess: (newWaiter) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_WAITER_SUCCESS,
    payload: newWaiter,
  }),
  fetchAddRestaurantWaiterFailure: (error) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_WAITER_FAILURE,
    payload: error,
  }),

  fetchAddWaiterToTableStart: (waiterId, tableId) => ({
    type: restaurantActions.FETCH_ADD_WAITER_TO_TABLE_START,
    payload: { waiterId, tableId },
  }),
  fetchAddWaiterToTableSuccess: (payload) => ({
    type: restaurantActions.FETCH_ADD_WAITER_TO_TABLE_SUCCESS,
    payload: payload,
  }),
  fetchAddWaiterToTableFailure: (error) => ({
    type: restaurantActions.FETCH_ADD_WAITER_TO_TABLE_FAILURE,
    payload: error,
  }),

  fetchDeleteWaiterFromTableStart: (waiterId, tableId) => ({
    type: restaurantActions.FETCH_DELETE_WAITER_FROM_TABLE_START,
    payload: { waiterId, tableId },
  }),
  fetchDeleteWaiterFromTableSuccess: (payload) => ({
    type: restaurantActions.FETCH_DELETE_WAITER_FROM_TABLE_SUCCESS,
    payload: payload,
  }),
  fetchDeleteWaiterFromTableFailure: (error) => ({
    type: restaurantActions.FETCH_DELETE_WAITER_FROM_TABLE_FAILURE,
    payload: error,
  }),

  fetchDeleteRestaurantTableStart: (tableId) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_START,
    payload: { tableId },
  }),
  fetchDeleteRestaurantTableSuccess: (payload) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_SUCCESS,
    payload: payload,
  }),
  fetchDeleteRestaurantTableFailure: (error) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_FAILURE,
    payload: error,
  }),

  fetchDeleteRestaurantWaiterStart: (waiterId) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_START,
    payload: { waiterId },
  }),
  fetchDeleteRestaurantWaiterSuccess: (payload) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_SUCCESS,
    payload: payload,
  }),
  fetchDeleteRestaurantWaiterFailure: (error) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_FAILURE,
    payload: error,
  }),

  fetchRestaurantRatingStart: (restaurantId) => ({
    type: restaurantActions.FETCH_RESTAURANT_RATING_START,
    payload: restaurantId,
  }),
  fetchRestaurantRatingSuccess: (ratings) => ({
    type: restaurantActions.FETCH_RESTAURANT_RATING_SUCCESS,
    payload: ratings,
  }),
  fetchRestaurantRatingFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANT_RATING_FAILURE,
    payload: error,
  }),
  fetchBrands: (restaurantId) => ({
    type: restaurantActions.FETCH_BRANDS,
    payload: restaurantId,
  }),
  fetchBrandTemplate: (restaurantId) => ({
    type: restaurantActions.FETCH_BRANDS,
    payload: restaurantId,
  }),
  switchBrandTemplate: (restaurantId) => ({
    type: restaurantActions.SWITCH_BRAND_TEMPLATE,
    payload: restaurantId,
  }),
  fetchRestaurantBrandsSuccess: (brands) => ({
    type: restaurantActions.FETCH_RESTAURANT_BRANDS_SUCCESS,
    payload: brands,
  }),
  fetchRestaurantBrandsFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANT_BRANDS_FAILURE,
    payload: error,
  }),

  /*Менеджеры*/
  fetchRestaurantManagersStart: (restaurantId) => ({
    type: restaurantActions.FETCH_RESTAURANT_MANAGERS_START,
    payload: restaurantId,
  }),
  fetchRestaurantManagersSuccess: (restaurants) => ({
    type: restaurantActions.FETCH_RESTAURANT_MANAGERS_SUCCESS,
    payload: restaurants,
  }),
  fetchRestaurantManagersFailure: (error) => ({
    type: restaurantActions.FETCH_RESTAURANT_MANAGERS_FAILURE,
    payload: error,
  }),

  fetchAddRestaurantManagerStart: (table) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_MANAGERS_START,
    payload: table,
  }),
  fetchAddRestaurantManagerSuccess: (newManager) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_MANAGERS_SUCCESS,
    payload: newManager,
  }),
  fetchAddRestaurantManagerFailure: (error) => ({
    type: restaurantActions.FETCH_ADD_RESTAURANT_MANAGERS_FAILURE,
    payload: error,
  }),

  fetchAddManagerToTableStart: (managerId, tableId) => ({
    type: restaurantActions.FETCH_ADD_MANAGER_TO_TABLE_START,
    payload: { managerId, tableId },
  }),
  fetchAddManagerToTableSuccess: (payload) => ({
    type: restaurantActions.FETCH_ADD_MANAGER_TO_TABLE_SUCCESS,
    payload: payload,
  }),
  fetchAddManagerToTableFailure: (error) => ({
    type: restaurantActions.FETCH_ADD_MANAGER_TO_TABLE_FAILURE,
    payload: error,
  }),

  fetchDeleteManagerFromTableStart: (managerId, tableId) => ({
    type: restaurantActions.FETCH_DELETE_MANAGER_FROM_TABLE_START,
    payload: { managerId, tableId },
  }),
  fetchDeleteManagerFromTableSuccess: (payload) => ({
    type: restaurantActions.FETCH_DELETE_MANAGER_FROM_TABLE_SUCCESS,
    payload: payload,
  }),
  fetchDeleteManagerFromTableFailure: (error) => ({
    type: restaurantActions.FETCH_DELETE_MANAGER_FROM_TABLE_FAILURE,
    payload: error,
  }),

  fetchDeleteRestaurantManagerStart: (managerId) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_START,
    payload: { managerId },
  }),
  fetchDeleteRestaurantManagerSuccess: (payload) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_SUCCESS,
    payload: payload,
  }),
  fetchDeleteRestaurantManagerFailure: (error) => ({
    type: restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_FAILURE,
    payload: error,
  }),
  unSubscribe: (value) => ({
    type: restaurantActions.UNSUBSCRIBE_CUSTOMER,
    payload: value,
  }),
  unsubscribeCustomerSuccess: (value) => ({
    type: restaurantActions.UNSUBSCRIBE_CUSTOMER_SUCCESS,
    payload: value,
  }),
};

export default restaurantActions;
