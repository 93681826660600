import styled from 'styled-components';

const PwaNotificationWrapper = styled.div`
  h4 {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }

  .pwa-modal-content {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 30px;
  }
  .pwa-modal-content img {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-right: 10px;
    border-radius: 12px;
  }
  .pwa-restaurant-info {
    width: 100%;
  }
  .pwa-close-btn,
  .pwa-install-btn {
    width: 48%;
    border: none;
    padding: 10px;
    margin-right: 2%;
    border-radius: 30px;
  }

  .pwa-close-btn {
    color: #000;
    background: #efefef;
  }
  .pwa-install-btn {
    color: #fff;
    background: #19bf88;
    margin-right: 0;
  }
`;

export default PwaNotificationWrapper;
