import trainingActions from './actions';
import profileData from './brand.data';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
};
export default function profileReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case trainingActions.FETCH_BRAND_DATA_SUCCESS:
      const { brandName, id } = action.payload;
      if (brandName !== '') {
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: null,
        };
      } else if (brandName === '') {
        return {
          ...state,
          data: { ...profileData, id: id },
          loading: false,
          error: null,
        };
      }
    case trainingActions.FETCH_BRAND_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case trainingActions.UPDATE_TRAINING_FILE_SUCCESS:
      return {
        ...state,
      };
    case trainingActions.UPDATE_TRAINING_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case trainingActions.UPLOAD_FILE_SUCCESS: {
      const { fileLink, fileType, key, brandId } = action.payload;
      state.data[key][fileType] = fileLink[0];
      console.log('new', state.data[key]);
      return {
        ...state,
      };
    }
    case trainingActions.UPLOAD_FILE_FAILURE:
      console.log(action.payload);

      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case trainingActions.ADD_VIDEO:
      const key = action.key;
      const newVideo = {
        group: false,
        name: 'Новое видео',
        url: '',
        id: 0,
        fieldName: key,
      };

      return {
        ...state,
        data: {
          ...state.data,
          [key]: [...state.data[key], newVideo],
        },
      };

    case trainingActions.CREATE_TRAINING_VIDEO_SUCCESS: {
      return {
        ...state,
      };
    }
    case trainingActions.CREATE_TRAINING_VIDEO_FAILURE:
      console.log(action.payload);

      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case trainingActions.DELETE_TRAINING_VIDEO_SUCCESS: {
      const video = action.payload;
      let videos = state.data[video.fieldName].slice();
      const pIdx = videos.findIndex((p) => p.id === video.id);

      if (pIdx !== -1) {
        videos.splice(pIdx, 1);
      }
      console.log(videos);
      if (videos.length === 0)
        videos = [
          {
            group: false,
            name: '',
            url: '',
            id: 0,
            fieldName: video.fieldName,
          },
        ];

      return {
        ...state,
        data: {
          ...state.data,
          [video.fieldName]: videos,
        },
      };
    }
    case trainingActions.DELETE_TRAINING_VIDEO_FAILURE:
      console.log(action.payload);

      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
