// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import localEatsActions from './actions';
import siteConfig from '@iso/config/site.config';

export const onMarketplaceDataRequest = async (code) =>
  await fetch(`${siteConfig.apiUrl}/marketplace?marketplace_code=${code}`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMarketplaceDataEffect({ payload }) {
  try {
    let StopLists = yield call(onMarketplaceDataRequest, payload);
    yield put(localEatsActions.fetchMarketplaceDataSuccess(StopLists));
  } catch (error) {
    yield put(localEatsActions.fetchMarketplaceDataFailure(error));
  }
}

export default function* localEatsSaga() {
  yield all([
    takeEvery(
      localEatsActions.FETCH_MARKETPLACE_DATA_START,
      fetchMarketplaceDataEffect
    ),
  ]);
}
