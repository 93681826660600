import firebase from 'firebase/app';
import 'firebase/messaging';
import isoConfig from '@iso/config/firebase.config';
import siteConfig from '@iso/config/site.config';
// import { getAnalytics } from "firebase/analytics";

firebase.initializeApp(isoConfig);
let messaging;
console.log(
  'firebase.messaging.isSupported()',
  firebase.messaging.isSupported()
);
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  console.log('messaging', messaging);

  if (
    Notification.permission === 'granted' &&
    localStorage.getItem('id') &&
    parseInt(localStorage.getItem('id')) !== 0
  ) {
    console.log('permission');
    getToken();
  }

  messaging.onMessage(function (payload) {
    console.log('mess');
    Notification.requestPermission(function (permission) {
      console.log('requestPermission');
      if (permission === 'granted') {
        navigator.serviceWorker.ready
          .then(function (registration) {
            const notificationTitle =
              payload?.notification?.title || 'New notification!';
            const notificationOptions = {
              body: payload?.notification?.body || 'Notification',
            };
            registration.showNotification(
              notificationTitle,
              notificationOptions
            );
          })
          .catch(function (error) {
            console.log('ServiceWorker registration failed ', error);
          });
      }
    }).catch(function (error) {
      console.log('Permission error ', error);
    });
  });
}

function getToken() {
  console.log('CALLED');
  if (firebase.messaging.isSupported()) {
    console.log('1');
    Notification.requestPermission()
      .then(function () {
        console.log('2');
        // Get Instance ID token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        messaging
          .getToken()
          .then(function (currentToken) {
            console.log('currentToken--------------', currentToken);
            console.log('3');
            if (currentToken) {
              if (!isTokenSentToServer(currentToken)) {
                console.log('Sending token to server...');
                sendTokenToServer(currentToken)
                  .then((r) => {
                    setTokenSentToServer(currentToken);
                    console.log('Token successful sending to server ', r);
                  })
                  .catch(function (error) {
                    console.log('Error sending token to server', error);
                    setTokenSentToServer(false);
                  });
              } else {
                console.log(
                  "Token already sent to server so won't send it again unless it changes"
                );
              }
            } else {
              console.log(
                'No Instance ID token available. Request permission to generate one'
              );
              setTokenSentToServer(false);
            }
          })
          .catch(function (error) {
            console.log(
              'No Instance ID token available. Request permission to generate one',
              error
            );
            setTokenSentToServer(false);
          });
      })
      .catch(function (error) {
        console.log('An error occurred while retrieving token', error);
      });
  }
}

const sendTokenToServer = async (currentToken) => {
  await fetch(`${siteConfig.apiUrl}/pwaSettings`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      id: 0,
      user_id: parseInt(localStorage.getItem('id')),
      restaurant_id: parseInt(localStorage.getItem('restaurantId')),
      endpoint: currentToken,
      expirationTime: '',
      auth: '',
      p256dh: '',
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function setTokenSentToServer(currentToken) {
  if (currentToken) {
    window.localStorage.setItem('sentFirebaseMessagingToken', currentToken);
  } else {
    window.localStorage.removeItem('sentFirebaseMessagingToken');
  }
}

function isTokenSentToServer(currentToken) {
  return (
    window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken
  );
}

// const analytics = getAnalytics(app);
export default firebase;
export { getToken };
