import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

// const Signin = lazy(() => import('@iso/containers/Pages/SignIn/SignIn'));

const DashboardNonConfirm = lazy(() =>
  import('./containers/Dashboard/DashboardNonConfirm')
);
const DashboardGuest = lazy(() =>
  import('./containers/Dashboard/DashboardGuest')
);
const DashboardKitchen = lazy(() =>
  import('./containers/Dashboard/DashboardKitchen')
);
const DashboardMarketplace = lazy(() =>
  import('./containers/Dashboard/DashboardMarketplace')
);
const DashboardNetworkAdmin = lazy(() =>
  import('./containers/Dashboard/DashboardNetworkAdmin')
);
const DashboardWaiter = lazy(() =>
  import('./containers/Dashboard/DashboardWaiter')
);
const Intro = lazy(() => import('./containers/Dashboard/Intro'));

const DashboardAdmin = lazy(() =>
  import('./containers/Dashboard/DashboardAdmin')
);

const DashboardReferral = lazy(() =>
  import('./containers/Dashboard/DashboardReferral')
);

const AccountSettingsNew = lazy(() =>
  import('@iso/containers/AccountSettingsNew/AccountSettingsNew')
);

function RootRoutingComponent() {
  localStorage.setItem('notices', true);
  const getPath = (isLoggedIn) => {
    if (!isLoggedIn) {
      return '/signin';
    }
    switch (localStorage.getItem('userRole')) {
      case 'network_admin':
        return '/network_admin';
      case 'kitchen':
        return '/dashboard';
      case 'admin':
        return '/admin';
      case 'guest':
        return '/guest';
      case 'marketplace':
        return '/marketplace';
      case 'waiter':
        return '/waiter';
      case 'referral':
        return '/referral';
      default:
        return '/signin';
    }
  };
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const path = getPath(isLoggedIn);
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: path,
        state: { from: location },
      }}
    />
  );
}

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: RootRoutingComponent,
    // component: lazy(() =>
    //   import('@iso/containers/Pages/SelectSubscription/SelectSubscription')
    // ),
  } /*
  {
    path: PUBLIC_ROUTE.SELECT_SUBSCRIPTION,
    component: lazy(() =>
      import('@iso/containers/Pages/SelectSubscription/SelectSubscription')
    ),
  },*/,
  {
    path: PUBLIC_ROUTE.LOCAL_EATS,
    component: lazy(() => import('@iso/containers/Pages/LocalEats/LocalEats')),
  },
  {
    path: PUBLIC_ROUTE.EMAIL_CONFIRM,
    component: lazy(() =>
      import('@iso/containers/Pages/EmailConfirm/EmailConfirm')
    ),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SELECT_SUBSCRIPTION,
    component: lazy(() =>
      import('@iso/containers/Pages/SelectSubscription/SelectSubscription')
    ),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.UNSUBSCRIBE,
    component: lazy(() =>
      import('@iso/containers/Pages/Unsubscribe/Unsubscribe')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import('@iso/containers/Authentication/Auth0/Auth0Callback')
    ),
  },
];

function PrivateRouteNonConfirm({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'nonconfirm') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteKitchen({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'kitchen') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteMarketplace({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'marketplace') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteNetworkAdmin({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'network_admin') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteWaiter({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'waiter') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteTraining({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'training') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteGuest({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'guest') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteAdmin({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'admin') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteReferral({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'referral') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

function PrivateRouteAccountSettingsNew({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  if (isLoggedIn) {
    if (localStorage.getItem('id_token')) {
      if (localStorage.getItem('userRole') === 'admin') {
        return <Route {...rest}>{children}</Route>;
      }
    }
  }
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRouteNonConfirm path="/nonconfirm">
              <DashboardNonConfirm />
            </PrivateRouteNonConfirm>
            <PrivateRouteGuest path="/guest">
              <DashboardGuest />
            </PrivateRouteGuest>
            <PrivateRouteTraining path="/training">
              <DashboardKitchen />
            </PrivateRouteTraining>
            <PrivateRouteMarketplace path="/marketplace">
              <DashboardMarketplace />
            </PrivateRouteMarketplace>
            <PrivateRouteNetworkAdmin path="/network_admin">
              <DashboardNetworkAdmin />
            </PrivateRouteNetworkAdmin>
            <PrivateRouteWaiter path="/waiter">
              <DashboardWaiter />
            </PrivateRouteWaiter>
            <PrivateRouteKitchen path="/dashboard">
              <DashboardKitchen />
            </PrivateRouteKitchen>
            <PrivateRouteKitchen path="/intro/:step/:catId?">
              <Intro />
            </PrivateRouteKitchen>
            <PrivateRouteAdmin path="/admin">
              <DashboardAdmin />
            </PrivateRouteAdmin>
            <PrivateRouteReferral path="/referral">
              <DashboardReferral />
            </PrivateRouteReferral>
            <PrivateRouteAccountSettingsNew path="/account-settings">
              <AccountSettingsNew />
            </PrivateRouteAccountSettingsNew>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
