import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
// import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  body.no-jivo jdiv {
    display: none;
  }

  :root {
    /*COLORS*/
    --color1: #ffffff; /*Белый*/
    --color2: #E5E5E5; /*Серый*/
    --color3: #FBFBFB; /*Светло Серый*/
    --color4: #2A2B2C; /*Темно серый (для текста)*/
    --color5: #2367E4; /*Синий*/
    --color6: #C4C4C4; /*Серый button */
    --color7: #ACAFB6; /*Серый placeholder */
    --color8: #F2F3F3; /*Серый button */
    --color9: #535455; /*Тёмно Серый ссылки в меню */
    --fonts: #4E4E4F; /*Шрифт*/

    /*COLORS STATUS*/
    --new: #44BCFF; /*Новый заказ*/
    --on-the-way: #44BCFF; /*В пути*/
    --adopted: #84CF5D; /*Заказ принят*/
    --canceled: #303031; /*Отменен рестораном*/
    --canceled-client: #DD1B18; /*Отменен клиентом*/
    --pickup: #FFA263; /*Произведен самовывоз*/
    --delivered: #9275FA; /*Заказ доставлен*/
    --cooking: #DFCD2A; /*Заказ готовится*/
    --ready: #3E8033; /*Заказ готов*/
  }


  .arrow {
    position: fixed !important;
    pointer-events: none;
    z-index: 0;
    padding-left: 25px;
  }

  .arrow__path {
    stroke: rgb(227, 180, 59);
    fill: transparent;
    stroke-width: 3;
  }

  .arrow__head {
    fill: rgb(227, 180, 59);
    stroke: rgb(227, 180, 59);
    stroke-width: 1px;
  }

  .arrowNoPadding {
    position: fixed !important;
    pointer-events: none;
    z-index: 0;
  }

  .arrowNoPadding__path {
    stroke: rgb(227, 180, 59);
    fill: transparent;
    stroke-width: 3;
  }

  .arrowNoPadding__head {
    fill: rgb(227, 180, 59);
    stroke: rgb(227, 180, 59);
    stroke-width: 0.1px;
  }

  .arrowCenter {
    position: fixed !important;
    pointer-events: none;
    z-index: 0;
    padding-left: 8px;
  }

  .arrowCenter__path {
    stroke: rgb(227, 180, 59);
    fill: transparent;
    stroke-width: 3;
  }

  .arrowCenter__head {
    fill: rgb(227, 180, 59);
    stroke: rgb(227, 180, 59);
  }

  .ant-btn {
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8 !important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-group-item {
        margin: 5px 0;

        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        border-radius: 8px;

        .ant-modal-header {
          border-radius: 8px 8px 0 0;
        }

        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;

            h2 {
              margin: 0;
            }

            form {
              padding: 15px 0 3px;

              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }

  .subscription.ant-modal {
    .ant-modal-content {
      border-radius: 8px;

      .ant-modal-header {
        border-radius: 8px 8px 0 0;
      }

      .ant-modal-body {
        .render-form-wrapper {
          padding: 10px;

          h2 {
            margin: 0;
          }

          form {
            padding: 15px 0 3px;

            .field-container {
              margin-bottom: 26px;
            }
          }
        }

        .anticon {
          display: none;
          width: 0;
        }

        .ant-modal-confirm-body ant-modal-confirm-title {
          text-align: center !important;
          font-weight: 600;
          font-size: 22px;
          backround: red
        }

        .ant-modal-confirm-content {
          margin-left: 0;
          margin-top: 50px;
          margin-bottom: 50px;
        }

        .ant-modal-confirm-btns {
          display: none;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8 !important;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img,
  svg {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }

  .ant-row:not(.ant-form-item) {${
    '' /* margin-left: -8px;
  margin-right: -8px; */
  };

    &:before,
    &:after {
      display: none;
    }
  }

  .ant-row > div {
    padding: 0;
  }

  .isoLeftRightComponent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .isoCenterComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /********** Add Your Global CSS Here **********/

  body {
    color: var(--fonts);
    font-size: 16px;
    -webkit-overflow-scrolling: touch;
  }

  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    font-family: 'Roboto', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  html ul {
    -webkit-padding-start: 0;
    list-style: none;
    margin-bottom: 0;
  }

  .scrollbar-track-y,
  .scrollbar-thumb-y {
    width: 5px !important;
  }

  .scrollbar-track-x,
  .scrollbar-thumb-x {
    height: 5px !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
  }

  .scrollbar-track {
    background: rgba(222, 222, 222, 0.15) !important;
  }

  .scrollbar-thumb {
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
  .ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
  .ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
  }

  /* Instagram Modal */

  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 935px;
    width: 100% !important;
  }

  @media only screen and (max-width: 991px) {
    .ant-modal-wrap.instagram-modal .ant-modal {
      padding: 0 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    .ant-modal-wrap.instagram-modal .ant-modal {
      max-width: 580px;
    }
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
    position: fixed;
    color: #fff;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
    font-size: 24px;
  }

  .ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  /********** Add Your Global RTL CSS Here **********/

  /* Popover */

  html[dir='rtl'] .ant-popover {
    text-align: right;
  }

  /* Ecommerce Card */

  html[dir='rtl'] .isoCardInfoForm .ant-input {
    text-align: right;
  }

  /* Modal */

  html[dir='rtl'] .has-success.has-feedback:after,
  html[dir='rtl'] .has-warning.has-feedback:after,
  html[dir='rtl'] .has-error.has-feedback:after,
  html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
  }

  html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
  }

  html[dir='rtl'] .ant-modal-footer {
    text-align: left;
  }

  html[dir='rtl'] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
  }

  html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
  }

  html[dir='rtl'] .ant-btn > .anticon + span,
  html[dir='rtl'] .ant-btn > span + .anticon {
    margin-right: 0.5em;
  }

  html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
  }

  html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
    margin-right: -14px;
    margin-left: 0;
  }

  /* Confirm */

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
  }

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
  }

  html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
    margin-right: 10px;
    margin-left: 0;
  }

  /* Message */

  html[dir='rtl'] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
  }

  /* Pop Confirm */

  html[dir='rtl'] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
  }

  html[dir='rtl'] .ant-popover-buttons {
    text-align: left;
  }

  /* Notification */

  html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
  }

  html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
  html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
    margin-right: 48px;
  }

  html[dir='rtl'] .ant-notification-notice-close {
    right: auto;
    left: 16px;
  }

  html[dir='rtl'] .ant-notification-notice-with-icon {
    left: 0;
  }

  /*  .ant-notification {
      top: 50% !important;
      right: 40% !important;
    }*/
  /* Dropzone */

  html[dir='rtl'] .dz-hidden-input {
    display: none;
  }

  /*div.ant-tooltip {
    .ant-tooltip-inner {
      border-radius: 8px;
      background-color: ${palette('whiteColor', 0)};
      padding: 12px 20px;
      min-width: 300px;
      .isoMenuHolder {
        display: flex;
        align-items: center;
      }
    }
  }
*/
  div&.ant-tooltip-inner {
    border-radius: 8px;
    color: black;
    background-color: ${palette('whiteColor', 0)};
    padding: 8px 12px;
    //min-width: 300px;

    .isoMenuHolder {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .ant-popover {
    .ant-popover-content {
      .ant-popover-inner {
        border-radius: 8px;
      }
    }
  }


  /* new order notification */
  .ant-notification {
    width: 100%;
    /* top: 0 !important; */
  }

  .custom-class {
    width: 100%;
    padding: 0 !important;
    top: -24px
  }

  .custom-class .ant-notification-notice {
    width: 100% !important;
    padding: 0 !important;
  }

  .custom-class .ant-notification-notice-message {
    display: none;
  }

  .custom-class .ant-notification-notice-description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #2367e4;
    animation: blinkingBackground 1s infinite;
  }

  .custom-class .ant-notification-notice-close {
    display: none;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text-container .textCircle {
    width: 366px;
    height: 366px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid #1f5ccd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .text-container .textCircle h3 {
    font-weight: 800;
    font-size: 82px;
    line-height: 87px;
    text-align: center;
    color: #979797;
    margin: 0;
    margin-bottom: 10px;
  }

  .text-container .textCircle p {
    font-weight: 500;
    font-size: 26px;
    line-height: 116.5%;
    text-align: center;
    color: #2a2b2c;
    margin: 0;
  }

  .text-container .textClose {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-top: 7rem;
    padding: 5px 27px;
    width: fit-content;
    border-radius: 13px;
    cursor: pointer;
    text-decoration: underline;
  }

  @media only screen and (max-width: 960px) {
    .text-container .textCircle {
      width: 186px;
      height: 186px;
    }

    .text-container .textCircle h3 {
      font-size: 42px;
      line-height: 45px;
    }

    .text-container .textCircle p {
      font-size: 16px;
      line-height: 116.5%;
    }

    .text-container .textClose {
      font-size: 16px;
      line-height: 116.5%;
    }
  }


  @keyframes blinkingBackground {
    0% {
      background-color: #2367e4;
    }
    75% {
      background-color: #85a5e2;
    }
    100% {
      background-color: #2367e4;
    }
  }

`;

export default GlobalStyles;
