import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { notification } from 'antd';
import { setApi } from '@iso/components/Notification';
import PwaNotification from './PwaNotification';

const App = () => {
  const [api, contextHolder] = notification.useNotification();
  setApi(api);

  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <PwaNotification />
          {contextHolder}
          <GlobalStyles />
          <Routes />
        </>
      </AppProvider>
    </Provider>
  );
};
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
