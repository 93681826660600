import siteConfig from '@iso/config/site.config';

const trainingActions = {
  FETCH_BRAND_DATA_START: 'FETCH_BRAND_DATA_START',
  FETCH_BRAND_DATA_SUCCESS: 'FETCH_BRAND_DATA_SUCCESS',
  FETCH_BRAND_DATA_FAILURE: 'FETCH_BRAND_DATA_FAILURE',
  FETCH_UPDATE_TRAINING_FILE: 'FETCH_UPDATE_TRAINING_FILE',
  FETCH_UPDATE_TRAINING_VIDEO: 'FETCH_UPDATE_TRAINING_VIDEO',
  UPDATE_TRAINING_FILE_SUCCESS: 'UPDATE_TRAINING_FILE_SUCCESS',
  UPDATE_TRAINING_FILE_FAILURE: 'UPDATE_TRAINING_FILE_FAILURE',
  SET_BRAND_DATA: 'SET_BRAND_DATA',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',
  ADD_VIDEO: 'ADD_VIDEO',
  CREATE_TRAINING_VIDEO: 'CREATE_TRAINING_VIDEO',
  CREATE_TRAINING_VIDEO_SUCCESS: 'CREATE_TRAINING_VIDEO_SUCCESS',
  CREATE_TRAINING_VIDEO_FAILURE: 'CREATE_TRAINING_VIDEO_FAILURE',
  DELETE_TRAINING_VIDEO: 'DELETE_TRAINING_VIDEO',
  DELETE_TRAINING_VIDEO_SUCCESS: 'DELETE_TRAINING_VIDEO_SUCCESS',
  DELETE_TRAINING_VIDEO_FAILURE: 'DELETE_TRAINING_VIDEO_FAILURE',

  fetchBrandDataStart: (brandId) => ({
    type: trainingActions.FETCH_BRAND_DATA_START,
    payload: {
      brandId,
    },
  }),
  fetchBrandDataSuccess: (profileBrand) => ({
    type: trainingActions.FETCH_BRAND_DATA_SUCCESS,
    payload: profileBrand,
  }),
  fetchBrandDataFailure: (error) => ({
    type: trainingActions.FETCH_BRAND_DATA_FAILURE,
    payload: error,
  }),

  fetchUpdateTrainingFile: (data, brandId) => ({
    type: trainingActions.FETCH_UPDATE_TRAINING_FILE,
    payload: data,
    brandId,
  }),
  fetchUpdateTrainingVideo: (data, brandId) => ({
    type: trainingActions.FETCH_UPDATE_TRAINING_VIDEO,
    payload: data,
    brandId,
  }),
  updateTrainingFileSuccess: (data) => ({
    type: trainingActions.UPDATE_TRAINING_FILE_SUCCESS,
    payload: data,
  }),
  updateTrainingFileFailure: (error) => ({
    type: trainingActions.UPDATE_TRAINING_FILE_FAILURE,
    payload: error,
  }),

  uploadFile: (file, fileType, key, brandId) => ({
    type: trainingActions.UPLOAD_FILE,
    payload: { file, fileType },
    key,
    brandId,
  }),
  uploadFileSuccess: (fileLink, fileType, key, brandId) => ({
    type: trainingActions.UPLOAD_FILE_SUCCESS,
    payload: { fileLink, fileType, key, brandId },
  }),
  uploadFileFailure: (error) => ({
    type: trainingActions.UPLOAD_FILE_FAILURE,
    payload: error,
  }),
  addVideo: (key) => {
    return (dispatch) => {
      dispatch({
        type: trainingActions.ADD_VIDEO,
        key,
      });
    };
  },

  fetchCreateTrainingVideo: (data, brandId) => ({
    type: trainingActions.CREATE_TRAINING_VIDEO,
    payload: data,
    brandId,
  }),
  fetchCreateTrainingVideoSuccess: (data) => ({
    type: trainingActions.CREATE_TRAINING_VIDEO_SUCCESS,
    payload: data,
  }),
  fetchCreateTrainingVideoFailure: (error) => ({
    type: trainingActions.CREATE_TRAINING_VIDEO_FAILURE,
    payload: error,
  }),

  fetchDeleteTrainingVideo: (data, brandId) => ({
    type: trainingActions.DELETE_TRAINING_VIDEO,
    payload: data,
    brandId,
  }),
  fetchDeleteTrainingVideoSuccess: (data) => ({
    type: trainingActions.DELETE_TRAINING_VIDEO_SUCCESS,
    payload: data,
  }),
  fetchDeleteTrainingVideoFailure: (error) => ({
    type: trainingActions.DELETE_TRAINING_VIDEO_FAILURE,
    payload: error,
  }),

  async uploadBrandFile(file) {
    const response = await fetch(`${siteConfig.apiUrl}/upload`, {
      crossDomain: true,
      method: 'POST',
      mode: 'cors',
      body: file,
    });
    return await response.json();
  },

  async options() {
    const response = fetch(`${siteConfig.apiUrl}/options`, {
      crossDomain: true,
      method: 'OPTIONS',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return await response;
  },
};

export default trainingActions;
