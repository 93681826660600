import { notificationApi } from './Notification';
import { notification } from 'antd';
import React from 'react';
import song from '@iso/assets/audio/Sound_16368.mp3';
import songOld from '@iso/assets/audio/Sound_16369.mp3';
import songGre from '@iso/assets/audio/Sound_16370.mp3';

const createNotification = (type, message, description) => {
  let resId = localStorage.getItem('restaurantId');
  let resSong = resId === '7740' ? songGre : songOld;
  const player = new Audio(resSong);

  if (
    localStorage.getItem('notices') === true ||
    localStorage.getItem('notices') === 'true'
  ) {
    player.addEventListener(
      'ended',
      function () {
        player.currentTime = 0;
        player.play().then(() => {});
      },
      false
    );
    const playPromise = player.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // console.log('audio played auto');
        })
        .catch((error) => {
          console.log('playback prevented', error);
        });
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const btnClick = function () {
    player.pause();
    // location.reload();
    // notification.close(key);
    notification.destroy();
    scrollToTop();
    window.location.href = '/dashboard/active_orders';

    /*    window
      .open(
        'https://orderbyte.io/dashboard/active_orders'
        ,"_self");*/
  };

  const handleClose = function () {
    player.pause();
    // location.reload();
    // notification.close(key);
    notification.destroy();
    scrollToTop();
  };

  const viewOrderDesign = (
    <>
      <div className="text-container">
        <div className="textCircle" onClick={btnClick}>
          <div>
            <h3>
              NEW
              <br />
              ORDER
            </h3>
            <p>Press to view</p>
          </div>
        </div>
        <div className="textClose" onClick={handleClose}>
          Close
        </div>
      </div>
    </>
  );

  setTimeout(() => {
    notification.destroy();
    notificationApi.open({
      message: 'Notification Title',
      description: viewOrderDesign,
      className: 'custom-class',
      duration: 0,
      style: { width: '100%' },
    });
  }, 0);
};
export default createNotification;
