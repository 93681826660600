// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import menuActions from './actions';
import siteConfig from '@iso/config/site.config';
import { v4 as uuidV4 } from 'uuid';
import Notification from '@iso/components/Notification';

async function uploadFiles(file) {
  const response = await fetch(`${siteConfig.apiUrl}/upload`, {
    crossDomain: true,
    method: 'POST',
    mode: 'cors',
    body: file,
  });
  return await response.json();
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

async function getUploadedImageUrl(url) {
  if (!url.startsWith('data:')) {
    return url;
  }
  const blob = dataURItoBlob(url);
  let ext = '';
  switch (blob.type) {
    case 'image/png':
      ext = '.png';
      break;
    case 'image/jpg':
      ext = '.jpg';
      break;
    case 'image/jpeg':
      ext = '.jpeg';
      break;
    case 'image/webp':
      ext = '.webp';
      break;
    case 'image/jfif':
      ext = '.jfif';
      break;
    case 'image/avif':
      ext = '.avif';
      break;
  }
  const formData = new FormData();
  formData.append('menus', blob, uuidV4() + ext);
  console.log('uploadFiles', blob);
  return uploadFiles(formData).then((result) => result.fileLinks[0]);
}

const getLanguage = () => localStorage.getItem('menuLocale');

export const onMenuRequest = async (brandId) =>
  await fetch(
    `${siteConfig.apiUrl}/getMenus/` +
      brandId +
      '?' +
      new URLSearchParams({ language: getLanguage() })
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onMenuRequestV2 = async (brandId, restaurantId) =>
  await fetch(`${siteConfig.apiUrl}/v2menu/${brandId}/${restaurantId}`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onMenuRequestIngredient = async (productId) =>
  await fetch(`${siteConfig.apiUrl}/ingredients?product=${productId}`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onCategoriesRequest = async () =>
  await fetch(
    `${siteConfig.apiUrl}/getCategories/` +
      '?' +
      new URLSearchParams({ language: getLanguage() })
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddProduct = async (brandId, newProduct) => {
  console.log('newProduct', newProduct);
  newProduct = { ...newProduct };
  if (newProduct.imageUrl)
    newProduct.imageUrl = await getUploadedImageUrl(newProduct.imageUrl);
  return await fetch(
    `${siteConfig.apiUrl}/product/` +
      brandId +
      '?' +
      new URLSearchParams({ language: getLanguage() }),
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(newProduct),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onEditProduct = async (brandId, editProduct) => {
  editProduct = { ...editProduct };
  if (editProduct.imageUrl)
    editProduct.imageUrl = await getUploadedImageUrl(editProduct.imageUrl);

  return await fetch(
    `${siteConfig.apiUrl}/product/` +
      editProduct.id +
      '?' +
      new URLSearchParams({ language: getLanguage() }),
    {
      crossDomain: true,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(editProduct),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onDeleteProduct = async (uuid) =>
  await fetch(`${siteConfig.apiUrl}/deleteProduct/` + uuid, {
    crossDomain: true,
    method: 'DELETE',
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    // },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onRemoveProduct = async (brandId, productId) =>
  await fetch(`${siteConfig.apiUrl}/stopLists/${brandId}/${productId}`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onAddCategory = async (menuId, categoryId) => {
  return await fetch(
    `${siteConfig.apiUrl}/category` +
      '?' +
      new URLSearchParams({ language: getLanguage() }),
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({ menuId, categoryId }),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onCreateCategory = async (menuId, category) => {
  return await fetch(
    `${siteConfig.apiUrl}/createCategory/${menuId}` +
      '?' +
      new URLSearchParams({ language: getLanguage() }),
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(category),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onEditCategory = async (category, menuId) => {
  return await fetch(
    `${siteConfig.apiUrl}/category?menu_id=${menuId}` +
      '&' +
      new URLSearchParams({ language: getLanguage() }),
    {
      crossDomain: true,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify(category),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onDeleteCategory = async (menuId, categoryId) => {
  return await fetch(`${siteConfig.apiUrl}/category`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({ menuId, categoryId }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onAddCategoryGroup = async (menuId, categoryGroup) => {
  return await fetch(`${siteConfig.apiUrl}/createCategoryGroup/${menuId}`, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(categoryGroup),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onEditCategoryGroup = async (categoryGroup) => {
  if (categoryGroup.url)
    categoryGroup.url = await getUploadedImageUrl(categoryGroup.url);

  return await fetch(`${siteConfig.apiUrl}/categoryGroup`, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(categoryGroup),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

export const onDeleteCategoryGroup = async (menuId, categoryGroupId) => {
  return await fetch(`${siteConfig.apiUrl}/categoryGroup`, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      menuId: +menuId,
      categoryGroupId: +categoryGroupId,
    }),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};
export const onFilterRequest = async () =>
  await fetch(`${siteConfig.apiUrl}/getFilterItems/`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchMenuFilterEffect({}) {
  try {
    let filters = yield call(onFilterRequest);
    yield put(menuActions.fetchMenuFilterSuccess(filters));
  } catch (error) {
    yield put(menuActions.fetchMenuFilterFailure(error));
  }
}

function* fetchMenuDataEffect({ brandId }) {
  try {
    let StopLists = yield call(onMenuRequest, brandId);
    yield put(menuActions.fetchMenuDataSuccess(StopLists));
  } catch (error) {
    yield put(menuActions.fetchMenuDataFailure(error));
  }
}

function* fetchMenuDataEffectV2({ payload }) {
  try {
    let StopLists = yield call(
      onMenuRequestV2,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchMenuDataSuccessV2(StopLists));
  } catch (error) {
    yield put(menuActions.fetchMenuDataFailureV2(error));
  }
}

function* fetchMenuIngredientStart({ payload }) {
  try {
    let ingredientLists = yield call(
      onMenuRequestIngredient,
      payload.productId
    );
    yield put(menuActions.fetchMenuIngredientSuccess(ingredientLists));
  } catch (error) {
    yield put(menuActions.fetchMenuIngredientFailure(error));
  }
}

function* fetchCategoriesDataEffect() {
  try {
    let categories = yield call(onCategoriesRequest);
    yield put(menuActions.fetchCategoriesDataSuccess(categories));
  } catch (error) {
    yield put(menuActions.fetchCategoriesDataFailure(error));
  }
}

function* addProduct({ brandId, payload }) {
  try {
    let newProduct = yield call(onAddProduct, brandId, payload);
    yield put(menuActions.addProductSuccess(newProduct));
  } catch (error) {
    yield put(menuActions.addProductFailure(error));
  }
}

function* editProduct({ brandId, payload }) {
  try {
    let newProduct = yield call(onEditProduct, brandId, payload);

    yield put(menuActions.editProductSuccess(newProduct));
  } catch (error) {
    yield put(menuActions.editProductFailure(error));
  }
}

function* deleteProduct({ payload }) {
  try {
    let deletedProduct = yield call(onDeleteProduct, payload.uuid);

    yield put(menuActions.deleteProductSuccess(payload.id));
  } catch (error) {
    yield put(menuActions.deleteProductFailure(error));
  }
}

function* addCategory({ menuId, categoryId }) {
  try {
    yield call(onAddCategory, menuId, categoryId);

    yield put(menuActions.addCategorySuccess());
  } catch (error) {
    yield put(menuActions.addCategoryFailure(error));
  }
}

function* createCategory({ menuId, category }) {
  try {
    const newCategory = yield call(onCreateCategory, menuId, category);
    console.log(newCategory);

    yield put(menuActions.createCategorySuccess(newCategory));
  } catch (error) {
    yield put(menuActions.createCategoryFailure(error));
  }
}

function* editCategory({ category, menuId }) {
  try {
    let editCategory = yield call(onEditCategory, category, menuId);

    yield put(menuActions.editCategorySuccess(editCategory));
  } catch (error) {
    yield put(menuActions.editCategoryFailure(error));
  }
}

function* deleteCategory({ menuId, categoryId }) {
  try {
    yield call(onDeleteCategory, menuId, categoryId);

    yield put(menuActions.deleteCategorySuccess(categoryId));
  } catch (error) {
    yield put(menuActions.deleteCategoryFailure(error));
  }
}

function* addCategoryGroup({ payload }) {
  try {
    const { menuId, categoryGroup } = payload;
    let newGroup = yield call(onAddCategoryGroup, menuId, categoryGroup);
    newGroup = { ...newGroup, id: +newGroup.id };

    yield put(menuActions.createCategoryGroupSuccess(newGroup));
  } catch (error) {
    yield put(menuActions.createCategoryGroupFailure(error));
  }
}

function* editCategoryGroup({ payload }) {
  try {
    const { categoryGroup } = payload;
    let group = yield call(onEditCategoryGroup, categoryGroup);
    group = { ...group, id: +group.id };

    yield put(menuActions.editCategoryGroupSuccess(group));
  } catch (error) {
    yield put(menuActions.editCategoryGroupFailure(error));
  }
}

function* deleteCategoryGroup({ payload }) {
  try {
    const { menuId, categoryGroupId } = payload;
    yield call(onDeleteCategoryGroup, menuId, categoryGroupId);
    yield put(menuActions.deleteCategoryGroupSuccess(categoryGroupId));
  } catch (error) {
    yield put(menuActions.deleteCategoryGroupFailure(error));
  }
}

const onWlUrlRequest = async (brandId, restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/wlurl?id_restaurant=${restaurantId}&id_brand=${brandId}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchWlUrlStart({ payload }) {
  try {
    let wlurl = yield call(
      onWlUrlRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchWlUrlSuccess(wlurl));
  } catch (error) {
    yield put(menuActions.fetchWlUrlFailure(error));
  }
}

const onStatusRequest = async (payload) =>
  await fetch(
    `https://orderbyte.io/api/v1/stores/menu/status/?brandid=${payload.brandId}&restaurantid=${payload.restaurantId}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchStatusRequest({ payload }) {
  try {
    let statusRes = yield call(onStatusRequest, payload);
    yield put(menuActions.fetchStatusSuccess(statusRes));
  } catch (error) {
    yield put(menuActions.fetchStatusFailure(error));
  }
}

const onUploadMenuRequest = async (payload) => {
  let response = await fetch(
    `https://orderbyte.io/api/v1/stores/menu/?brandid=${payload.brandId}&restaurantid=${payload.restaurantId}`,
    {
      crossDomain: true,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      // console.log('error-------', err);
      Notification('error', 'You got some error.');
    });
  if (response) {
    Notification('success', 'Menu uploaded successfully');
  }
  return response;
};

function* uploadMenuStart({ payload }) {
  try {
    let menuData = yield call(onUploadMenuRequest, payload);
    // yield put(menuActions.fetchWlUrlSuccess(menuData));
  } catch (error) {
    yield put(menuActions.fetchWlUrlFailure(error));
  }
}

const onEmailRequest = async (payload) => {
  let data = {
    brand_id: payload,
  };
  let response = await fetch(
    `https://orderbyte.io/api/subscription/v1/api/ready`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        auth: 'order_xht4sAytlopr8hn_byte',
      },
      mode: 'cors',
      body: JSON.stringify(data),
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      // console.log('error-------', err);
      Notification('error', 'You got some error.');
    });
  if (response) {
    Notification('success', 'Email sent successfully');
  }
  return response;
};

function* sentEmailToCustomer({ payload }) {
  try {
    let menuData = yield call(onEmailRequest, payload);
    // yield put(menuActions.fetchWlUrlSuccess(menuData));
  } catch (error) {
    yield put(menuActions.fetchWlUrlFailure(error));
  }
}

const onPOSExportStatusRequest = async (brandId, restaurantId) =>
  await fetch(
    `${siteConfig.apiUrl}/rkImportData?id_restaurant=${restaurantId}&id_brand=${brandId}`
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchPOSExportStatusStart({ payload }) {
  try {
    let posData = yield call(
      onPOSExportStatusRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchPOSExportStatusSuccess(posData));
  } catch (error) {
    yield put(menuActions.fetchPOSExportStatusFailure(error));
  }
}

const onPOSExportRequest = async (brandId, restaurantId) => {
  return await fetch(
    `${siteConfig.apiUrl}/rkExportMenu?id_restaurant=${restaurantId}&id_brand=${brandId}`,
    {
      crossDomain: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    }
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });
};

function* fetchPOSExportStart({ payload }) {
  try {
    let posData = yield call(
      onPOSExportRequest,
      payload.brandId,
      payload.restaurantId
    );
    yield put(menuActions.fetchPOSExportSuccess(posData));
  } catch (error) {
    yield put(menuActions.fetchPOSExportFailure(error));
  }
}

const onCreateQRRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwlqr`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* fetchCreateQRStart({ payload }) {
  try {
    yield call(onCreateQRRequest, payload);

    yield put(menuActions.fetchCreateWLSuccess());
  } catch (error) {
    yield put(menuActions.fetchCreateWLFailure(error));
  }
}

const onCreateWLRequest = async (data) => {
  const response = await fetch(`${siteConfig.apiUrl}/createwl`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    crossDomain: true,
  });
  return response.json();
};

function* fetchCreateWLStart({ payload }) {
  try {
    yield call(onCreateWLRequest, payload);

    yield put(menuActions.fetchCreateWLSuccess());
  } catch (error) {
    yield put(menuActions.fetchCreateWLFailure(error));
  }
}

const onSetQrMenuPropertyRequest = async (menuId, state) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/qrMenuProperty?id_menu=${menuId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        state: state,
      }),
      crossDomain: true,
    }
  );
  return response.json();
};

function* setQrMenuProperty({ payload }) {
  try {
    const { status, menuId } = payload;
    let res = yield call(onSetQrMenuPropertyRequest, menuId, status);

    yield put(menuActions.setQrMenuPropertySuccess(res));
  } catch (error) {
    yield put(menuActions.setQrMenuPropertyFailure(error));
  }
}

const saveMenuSettingsRequest = async (payload) => {
  const response = await fetch(`${siteConfig.apiUrl}/saveMenuSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(payload),
    crossDomain: true,
  });
  return response.json();
};

function* saveMenuSettings({ brandId, payload }) {
  try {
    yield call(saveMenuSettingsRequest, payload);

    yield put(menuActions.saveMenuSettingsSuccess(brandId));
  } catch (error) {
    yield put(menuActions.saveMenuSettingsFailure(error));
  }
}

const translateMenuRequest = async (restaurantId, brandId, language) => {
  const response = await fetch(
    `${siteConfig.apiUrl}/translateMenu?language=${language}&brand_id=${brandId}&restaurant_id=${restaurantId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      crossDomain: true,
    }
  );
  return response.json();
};

function* translateMenu({ restaurantId, brandId, language }) {
  try {
    let status = yield call(
      translateMenuRequest,
      restaurantId,
      brandId,
      language
    );
    console.log({ status });
    yield put(menuActions.translateMenuSuccess(brandId));
  } catch (error) {
    yield put(menuActions.translateMenuFailure(error));
  }
}

export default function* menuSaga() {
  yield all([
    takeEvery(menuActions.FETCH_MENU_DATA_START, fetchMenuDataEffect),
    takeEvery(
      menuActions.FETCH_MENU_DATA_START_VERSION_TWO,
      fetchMenuDataEffectV2
    ),
    takeEvery(
      menuActions.FETCH_MENU_INGREDIENT_START,
      fetchMenuIngredientStart
    ),
    takeEvery(menuActions.FETCH_MENU_FILTER_START, fetchMenuFilterEffect),
    takeEvery(
      menuActions.FETCH_CATEGORIES_DATA_START,
      fetchCategoriesDataEffect
    ),
    takeEvery(menuActions.ADD_PRODUCT, addProduct),
    takeEvery(menuActions.EDIT_PRODUCT, editProduct),
    takeEvery(menuActions.DELETE_PRODUCT, deleteProduct),
    takeEvery(menuActions.ADD_CATEGORY, addCategory),
    takeEvery(menuActions.CREATE_CATEGORY, createCategory),
    takeEvery(menuActions.EDIT_CATEGORY, editCategory),
    takeEvery(menuActions.DELETE_CATEGORY, deleteCategory),
    takeEvery(menuActions.CREATE_CATEGORY_GROUP, addCategoryGroup),
    takeEvery(menuActions.EDIT_CATEGORY_GROUP, editCategoryGroup),
    takeEvery(menuActions.DELETE_CATEGORY_GROUP, deleteCategoryGroup),
    takeEvery(menuActions.FETCH_WLURL_START, fetchWlUrlStart),
    takeEvery(menuActions.FETCH_STATUS_REQUEST, fetchStatusRequest),
    takeEvery(menuActions.UPLOAD_MENU_START, uploadMenuStart),
    takeEvery(menuActions.SENT_EMAIL_CUSTOMER, sentEmailToCustomer),
    takeEvery(
      menuActions.FETCH_POS_EXPORT_STATUS_START,
      fetchPOSExportStatusStart
    ),
    takeEvery(menuActions.FETCH_POS_EXPORT_START, fetchPOSExportStart),
    takeEvery(menuActions.FETCH_CREATE_QR_START, fetchCreateQRStart),
    takeEvery(menuActions.FETCH_CREATE_WL_START, fetchCreateWLStart),
    takeEvery(menuActions.SET_QR_MENU_PROPERTY, setQrMenuProperty),
    takeEvery(menuActions.SAVE_MENU_SETTINGS, saveMenuSettings),
    takeEvery(menuActions.TRANSLATE_MENU, translateMenu),
  ]);
}
