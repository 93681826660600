import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import orderActions from '@iso/redux/orders/actions';
import actions from './actions';
import siteConfig from '@iso/config/site.config';
const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

const logoutRequest = async () => {
  const response = await fetch(`${siteConfig.apiUrl}/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('id_token'),
    },
    mode: 'cors',
    body: JSON.stringify({
      endpoint: localStorage.getItem('sentFirebaseMessagingToken'),
    }),
    crossDomain: true,
  });
  return response.json();
};

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield call(logoutRequest);
    localStorage.setItem('restaurantId', null);
    localStorage.setItem('id_token', null);

    orderActions.closeWsOrder();
    clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}

export function* registrationSuccess() {
  yield takeEvery(actions.REGISTRATION_SUCCESS, function* (payload) {
    console.log('yield REGISTRATION_SUCCESS', payload.token);
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* registrationError() {
  yield takeEvery(actions.REGISTRATION_ERROR, function* () {});
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(registrationSuccess),
    fork(registrationError),
    fork(logout),
  ]);
}
