import menuActions from './actions';
import Notification from '@iso/components/Notification';

const INITIAL_DATA = {
  data: null,
  menuData: null,
  ingredientData: null,
  filters: [],
  categories: [],
  loading: true,
  loadingIngredient: true,
  loadingCategories: true,
  savingProduct: false,
  wlUrl: null,
  statusData: null,
  posData: null,
  error: null,
};
export default function menuReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case menuActions.FETCH_MENU_DATA_START:
      return {
        ...state,
        data: null,
        loading: true,
      };
    case menuActions.FETCH_MENU_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case menuActions.FETCH_MENU_FILTER_SUCCESS:
      return {
        ...state,
        filters: action.payload,
        loading: false,
        error: null,
      };

    case menuActions.FETCH_MENU_DATA_START_VERSION_TWO:
      return {
        ...state,
        menuData: null,
        loading: true,
      };
    case menuActions.FETCH_MENU_DATA_SUCCESS_VERSION_TWO:
      return {
        ...state,
        menuData: action.payload,
        loading: false,
        error: null,
      };
    case menuActions.FETCH_MENU_INGREDIENT_START:
      return {
        ...state,
        ingredientData: null,
        loadingIngredient: true,
      };
    case menuActions.FETCH_MENU_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredientData: action.payload,
        loadingIngredient: false,
        error: null,
      };
    case menuActions.FETCH_MENU_DATA_FAILURE_VERSION_TWO:
    case menuActions.FETCH_MENU_DATA_FAILURE:
    case menuActions.FETCH_MENU_FILTER_FAILURE:
    case menuActions.FETCH_MENU_INGREDIENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case menuActions.FETCH_CATEGORIES_DATA_SUCCESS:
      return {
        ...state,
        loadingCategories: false,
        categories: action.payload,
        error: null,
      };
    case menuActions.FETCH_CATEGORIES_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case menuActions.ADD_PRODUCT:
    case menuActions.EDIT_PRODUCT: {
      return {
        ...state,
        savingProduct: true,
      };
    }
    case menuActions.ADD_PRODUCT_SUCCESS: {
      const product = action.payload;
      const products = state.menuData.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === product.id);

      if (pIdx === -1) {
        products.push(product);
      } else {
        products[pIdx] = product;
      }

      return {
        ...state,
        savingProduct: false,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            products: products,
          },
        },
      };
    }
    case menuActions.ADD_PRODUCT_FAILURE:
      Notification('warning', `Create product error!`);
      return {
        ...state,
        loading: false,
        savingProduct: false,
        error: action.payload,
      };

    case menuActions.CREATE_CATEGORY_SUCCESS: {
      const newCat = action.payload;
      const categories = [...state.menuData.menuItems.categories];
      categories.push(newCat);
      console.log({ newCat, categories });

      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categories: categories,
          },
        },
      };
    }
    case menuActions.ADD_CATEGORY_SUCCESS:
    case menuActions.DELETE_CATEGORY_SUCCESS:
      const category = action.payload;
      const categories = state.menuData.menuItems.categories.filter(
        (p) => parseInt(p.id) !== category
      );

      // const newСategories = categories.filter((p) => parseInt(p.id) !== category);
      console.log({ category, categories });

      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categories: categories,
          },
        },
      };
    case menuActions.EDIT_CATEGORY_SUCCESS: {
      const category = action.payload;
      let categories = state.menuData.menuItems.categories.slice();
      let index = categories.findIndex((p) => p.id === category.id);

      if (index === -1) {
        categories.push(category);
      } else {
        categories[index] = category;
      }
      categories.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categories: categories,
          },
        },
      };
    }
    case menuActions.CREATE_CATEGORY_GROUP_SUCCESS: {
      const newGroup = action.payload;
      let groups = [...state.menuData.menuItems.categoriesGroup, newGroup];
      groups = groups.sort((a, b) =>
        +a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder === +b.sortOrder ? 0 : -1
      );
      console.log(newGroup, groups);
      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categoriesGroup: groups,
          },
        },
      };
    }
    case menuActions.EDIT_CATEGORY_GROUP_SUCCESS: {
      const group = action.payload;
      const groups = [
        ...state.menuData.menuItems.categoriesGroup.filter(
          (g) => +g.id !== +group.id
        ),
        group,
      ].sort((a, b) =>
        +a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder === +b.sortOrder ? 0 : -1
      );
      console.log(group, groups);
      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categoriesGroup: groups,
          },
        },
      };
    }
    case menuActions.DELETE_CATEGORY_GROUP_SUCCESS: {
      const categoryGroupId = action.payload;
      const groups = [
        ...state.menuData.menuItems.categoriesGroup.filter(
          (g) => +g.id !== +categoryGroupId
        ),
      ];
      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            categoriesGroup: groups,
          },
        },
      };
    }
    case menuActions.EDIT_PRODUCT_SUCCESS: {
      const product = action.payload;
      const products = state.menuData.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === product.id);

      if (pIdx === -1) {
        products.push(product);
      } else {
        products[pIdx] = product;
      }

      return {
        ...state,
        savingProduct: false,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            products: products,
          },
        },
      };
    }

    case menuActions.EDIT_PRODUCT_FAILURE:
      Notification('warning', `Edit product error!`);
      return {
        ...state,
        savingProduct: false,
        loading: false,
        error: action.payload,
      };

    case menuActions.DELETE_PRODUCT_SUCCESS: {
      const productId = action.payload;
      const products = state.menuData.menuItems.products.slice();
      const pIdx = products.findIndex((p) => p.id === productId);

      if (pIdx !== -1) {
        products.splice(pIdx, 1);
      }

      return {
        ...state,
        menuData: {
          ...state.menuData,
          menuItems: {
            ...state.menuData.menuItems,
            products: products,
          },
        },
      };
    }
    case menuActions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case menuActions.FETCH_WLURL_SUCCESS: {
      return {
        ...state,
        wlUrl: action.payload,
      };
    }
    case menuActions.FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        statusData: action.payload,
      };
    }
    case menuActions.FETCH_POS_EXPORT_STATUS_SUCCESS: {
      return {
        ...state,
        posData: action.payload,
      };
    }
    case menuActions.FETCH_POS_EXPORT_SUCCESS: {
      return {
        posData: action.payload,
      };
    }
    case menuActions.FETCH_CREATE_WL_SUCCESS: {
      Notification(
        'success',
        `The site was created by`,
        'An in-house delivery website has been generated for the brand!'
      );
      return {
        ...state,
      };
    }
    case menuActions.FETCH_CREATE_WL_FAILURE: {
      Notification(
        'warning',
        `Failed to create a website :(`,
        'No own delivery website has been generated for the brand, please contact support'
      );
      return {
        ...state,
        error: action.payload,
      };
    }
    case menuActions.SET_QR_MENU_PROPERTY_SUCCESS: {
      Notification('success', `QR-меню сохранено`);
      return {
        ...state,
        menuData: {
          ...state.menuData,
          isQrMenu: action.payload.status.status,
        },
      };
    }
    case menuActions.SET_QR_MENU_PROPERTY_FAILURE: {
      Notification(
        'warning',
        `Не удалось сохранить свойстов :(`,
        'Не было сохранено свойство QR-меню'
      );
      return {
        ...state,
        error: action.payload,
      };
    }
    case menuActions.SAVE_MENU_SETTINGS_SUCCESS: {
      return {
        ...state,
      };
    }
    case menuActions.TRANSLATE_MENU_SUCCESS: {
      return {
        ...state,
      };
    }
    case menuActions.TRANSLATE_MENU_FAILURE:
    case menuActions.SAVE_MENU_SETTINGS_FAILURE:
    case menuActions.ADD_CATEGORY_FAILURE:
    case menuActions.CREATE_CATEGORY_FAILURE:
    case menuActions.EDIT_CATEGORY_FAILURE:
    case menuActions.DELETE_CATEGORY_FAILURE:
    case menuActions.FETCH_WLURL_FAILURE:
    case menuActions.FETCH_STATUS_FAILURE:
    case menuActions.FETCH_POS_EXPORT_STATUS_FAILURE:
    case menuActions.FETCH_POS_EXPORT_FAILURE:
      console.log(action.payload);
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
