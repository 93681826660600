import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import profileBrand from '@iso/redux/training/reducer';
import StopLists from '@iso/redux/stoplists/reducer';
import menu from '@iso/redux/menu/reducer';
import Questionnaires from '@iso/redux/questionnaireForm/reducer';
import RestaurantOrders from '@iso/redux/orders/reducer';
import Partners from '@iso/redux/partners/reducer';
import restaurants from '@iso/redux/restaurant/reducer';
import ReportGenerator from '@iso/redux/reportgenerator/reducer';
import AccountSettings from '@iso/redux/accountSettings/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import checklist from '@iso/redux/checklist/reducer';
import LocalEats from '@iso/redux/localEats/reducer';
import Delivery from '@iso/redux/delivery/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  modal,
  drawer,
  profile,
  profileBrand,
  StopLists,
  menu,
  Questionnaires,
  RestaurantOrders,
  Partners,
  restaurants,
  ReportGenerator,
  checklist,
  AccountSettings,
  Calendar,
  LocalEats,
  Delivery,
});
