import React, { useState } from 'react';
import Button from '@iso/components/uielements/button';
import IntlMessages from '@iso/components/utility/intlMessages';
import PwaNotificationWrapper from './PwaNotification.styles';
import { Modal } from 'antd';
import reactIcon from '@iso/assets/images/favicon.png';

let deferredPrompt;

function registerEventListener(trigger) {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can install the PWA
    trigger();
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
}

export default () => {
  let modalVisibleFormLocalStorage =
    localStorage.getItem('dontShowInstallPwa') || true;
  const [pwaModalVisible, setPwaModalVisible] = useState(
    modalVisibleFormLocalStorage
  );

  const installPwa = async () => {
    deferredPrompt.prompt();
    setPwaModalVisible(false);

    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
      setRerender((i) => i + 1);
    }
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
  };

  const dontShowInstallPwa = () => {
    localStorage['dontShowInstallPwa'] = true;
    setRerender((i) => i + 1);
    setPwaModalVisible(false);
  };

  const [renderIdx, setRerender] = useState(0);
  if (localStorage['dontShowInstallPwa']) {
    return null;
  }

  registerEventListener(() => setRerender(renderIdx + 1));

  if (!deferredPrompt?.prompt) {
    return null;
  }

  console.log('rendering buttons', renderIdx);
  return (
    <Modal visible={pwaModalVisible} footer={null} centered closable={false}>
      <PwaNotificationWrapper>
        <h4>
          <IntlMessages id="pwaNotification.caption" />
        </h4>
        <div className="pwa-modal-content">
          <img src={reactIcon} alt="" />
          <div className="pwa-restaurant-info">
            <p>Orderbyte</p>
            <p>https://orderbyte.io/</p>
          </div>
        </div>
        <Button className="pwa-close-btn" onClick={dontShowInstallPwa}>
          <IntlMessages id="pwaNotification.dontShow" />
        </Button>
        <Button className="pwa-install-btn" onClick={installPwa}>
          <IntlMessages id="pwaNotification.install" />
        </Button>
      </PwaNotificationWrapper>
    </Modal>
  );
};
