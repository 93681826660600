import React from 'react';
import actions from './actions';

const initState = {
  orderDelivery: [],
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.FETCH_POSTS_ORDER_DELIVERY_COST_SUCCESS: {
      return {
        ...state,
        orderDelivery: action.payload,
      };
    }
    case actions.FETCH_POSTS_ORDER_DELIVERY_COST_FAILURE: {
      console.log(action.payload);
      return {
        ...state,
        orderDelivery: [],
      };
    }
    default:
      return state;
  }
}
