import localEatsActions from './actions';

const INITIAL_DATA = {
  data: null,
  loading: true,
  error: null,
};
export default function localEatsReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case localEatsActions.FETCH_MARKETPLACE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case localEatsActions.FETCH_MARKETPLACE_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
