import { marginTemperatureRegime } from '@iso/containers/BrandTraining/BrandCard.style';

const profileData = {
  id: 1,
  menu: {
    pdf: '',
    doc: '',
    excel: '',
  },
  kbzhy: {
    pdf: '',
    doc: '',
    excel: '',
  },
  cookbook: {
    pdf: '',
    doc: '',
    excel: '',
  },
  marking: {
    pdf: '',
    doc: '',
    excel: '',
  },
  checklist: {
    pdf: '',
    doc: '',
    excel: '',
  },
  equipment: {
    pdf: '',
    doc: '',
    excel: '',
  },
  suppliers: {
    pdf: '',
    doc: '',
    excel: '',
  },
  productList: {
    pdf: '',
    doc: '',
    excel: '',
  },
  assemblingDishes: {
    pdf: '',
    doc: '',
    excel: '',
  },
  brandProcessMap: {
    pdf: '',
    doc: '',
    excel: '',
  },
  menuPosters: {
    pdf: '',
    doc: '',
    excel: '',
  },
  packingList: {
    pdf: '',
    doc: '',
    excel: '',
  },
  temperatureRegime: {
    pdf: '',
    doc: '',
    excel: '',
  },
  storageConditions: {
    pdf: '',
    doc: '',
    excel: '',
  },
  foodSafety: {
    pdf: '',
    doc: '',
    excel: '',
  },
  videoSemiSausage: [],
  videoMainDishes: [],
  videoOther: [],
  videoPackaging: [],
  videoBuildMainDishes: [],
  videoBuildOther: [],
  brandName: 'NEW YORKER WINGS',
};

export default profileData;
