import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import siteConfig from '@iso/config/site.config';

export const onPostsOrderDeliveryCost = async (orderId) =>
  await fetch(`${siteConfig.apiUrl}/deliverycost/${orderId}`)
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchPostsOrderDeliveryCost({ orderId }) {
  try {
    let deliveryCost = yield call(onPostsOrderDeliveryCost, orderId);

    yield put(actions.fetchPostsOrderDeliveryCostSuccess(deliveryCost));
  } catch (error) {
    yield put(actions.fetchPostsOrderDeliveryCostFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(
      actions.FETCH_POSTS_ORDER_DELIVERY_COST_START,
      fetchPostsOrderDeliveryCost
    ),
  ]);
}
