const actions = {
  FETCH_RKEEPER_SETTINGS_START: 'FETCH_RKEEPER_SETTINGS_START',
  FETCH_RKEEPER_SETTINGS_SUCCESS: 'FETCH_RKEEPER_SETTINGS_SUCCESS',
  FETCH_RKEEPER_SETTINGS_FAILURE: 'FETCH_RKEEPER_SETTINGS_FAILURE',

  CREATE_RKEEPER_SETTINGS_START: 'CREATE_RKEEPER_SETTINGS_START',
  SUBMIT_RKEEPER_SETTINGS_START: 'SUBMIT_RKEEPER_SETTINGS_START',
  SUBMIT_RKEEPER_SETTINGS_SUCCESS: 'SUBMIT_RKEEPER_SETTINGS_SUCCESS',
  SUBMIT_RKEEPER_SETTINGS_FAILURE: 'SUBMIT_RKEEPER_SETTINGS_FAILURE',

  FETCH_PARTNER_SETTINGS_START: 'FETCH_PARTNER_SETTINGS_START',
  FETCH_PARTNER_SETTINGS_SUCCESS: 'FETCH_PARTNER_SETTINGS_SUCCESS',
  FETCH_PARTNER_SETTINGS_FAILURE: 'FETCH_PARTNER_SETTINGS_FAILURE',

  SUBMIT_PARTNER_SETTINGS_START: 'SUBMIT_PARTNER_SETTINGS_START',
  SUBMIT_PARTNER_SETTINGS_SUCCESS: 'SUBMIT_PARTNER_SETTINGS_SUCCESS',
  SUBMIT_PARTNER_SETTINGS_FAILURE: 'SUBMIT_PARTNER_SETTINGS_FAILURE',

  SUBMIT_PARTNER_PLAN_START: 'SUBMIT_PARTNER_PLAN_START',
  SUBMIT_PARTNER_PLAN_SUCCESS: 'SUBMIT_PARTNER_PLAN_SUCCESS',
  SUBMIT_PARTNER_PLAN_FAILURE: 'SUBMIT_PARTNER_PLAN_FAILURE',

  SUBMIT_CREATE_SITE_START: 'SUBMIT_CREATE_SITE_START',
  SUBMIT_CREATE_SITE_SUCCESS: 'SUBMIT_CREATE_SITE_SUCCESS',
  SUBMIT_CREATE_SITE_FAILURE: 'SUBMIT_CREATE_SITE_FAILURE',

  SUBMIT_CREATE_MENU_START: 'SUBMIT_CREATE_MENU_START',
  SUBMIT_CREATE_MENU_SUCCESS: 'SUBMIT_CREATE_MENU_SUCCESS',
  SUBMIT_CREATE_MENU_FAILURE: 'SUBMIT_CREATE_MENU_FAILURE',

  SUBMIT_FILL_BRAND_START: 'SUBMIT_FILL_BRAND_START',
  SUBMIT_FILL_BRAND_SUCCESS: 'SUBMIT_FILL_BRAND_SUCCESS',
  SUBMIT_FILL_BRAND_FAILURE: 'SUBMIT_FILL_BRAND_FAILURE',

  SUBMIT_FILL_BAUTOMATION_START: 'SUBMIT_FILL_BAUTOMATION_START',
  SUBMIT_FILL_AUTOMATION_SUCCESS: 'SUBMIT_FILL_AUTOMATION_SUCCESS',
  SUBMIT_FILL_AUTOMATION_FAILURE: 'SUBMIT_FILL_AUTOMATION_FAILURE',

  SUBMIT_SITE_ADDRESS_START: 'SUBMIT_SITE_ADDRESS_START',
  SUBMIT_SITE_ADDRESS_SUCCESS: 'SUBMIT_SITE_ADDRESS_SUCCESS',
  SUBMIT_SITE_ADDRESS_FAILURE: 'SUBMIT_SITE_ADDRESS_FAILURE',

  SUBMIT_SITE_ADDRESS_DOMAIN_START: 'SUBMIT_SITE_ADDRESS_DOMAIN_START',
  SUBMIT_SITE_ADDRESS_DOMAIN_SUCCESS: 'SUBMIT_SITE_ADDRESS_DOMAIN_SUCCESS',
  SUBMIT_SITE_ADDRESS_DOMAIN_FAILURE: 'SUBMIT_SITE_ADDRESS_DOMAIN_FAILURE',

  FETCH_BRAND_SETTINGS_START: 'FETCH_BRAND_SETTINGS_START',
  FETCH_BRAND_SETTINGS_SUCCESS: 'FETCH_BRAND_SETTINGS_SUCCESS',
  FETCH_BRAND_SETTINGS_FAILURE: 'FETCH_BRAND_SETTINGS_FAILURE',

  FETCH_AUTOMATION_SETTINGS_START: 'FETCH_AUTOMATION_SETTINGS_START',
  FETCH_AUTOMATION_SETTINGS_SUCCESS: 'FETCH_AUTOMATION_SETTINGS_SUCCESS',
  FETCH_AUTOMATION_SETTINGS_FAILURE: 'FETCH_AUTOMATION_SETTINGS_FAILURE',

  SUBMIT_AUTOMATION_SETTINGS_START: 'SUBMIT_AUTOMATION_SETTINGS_START',
  SUBMIT_AUTOMATION_SETTINGS_SUCCESS: 'SUBMIT_AUTOMATION_SETTINGS_SUCCESS',
  SUBMIT_AUTOMATION_SETTINGS_FAILURE: 'SUBMIT_AUTOMATION_SETTINGS_FAILURE',

  FETCH_IIKO_SETTINGS_START: 'FETCH_IIKO_SETTINGS_START',
  FETCH_IIKO_SETTINGS_SUCCESS: 'FETCH_IIKO_SETTINGS_SUCCESS',
  FETCH_IIKO_SETTINGS_FAILURE: 'FETCH_IIKO_SETTINGS_FAILURE',

  CREATE_IIKO_SETTINGS_START: 'CREATE_IIKO_SETTINGS_START',
  SUBMIT_IIKO_SETTINGS_START: 'SUBMIT_IIKO_SETTINGS_START',
  SUBMIT_IIKO_SETTINGS_SUCCESS: 'SUBMIT_IIKO_SETTINGS_SUCCESS',
  SUBMIT_IIKO_SETTINGS_FAILURE: 'SUBMIT_IIKO_SETTINGS_FAILURE',

  FETCH_POS_INTEGRATION_START: 'FETCH_POS_INTEGRATION_START',
  FETCH_POS_INTEGRATION_SUCCESS: 'FETCH_POS_INTEGRATION_SUCCESS',
  FETCH_POS_INTEGRATION_FAILURE: 'FETCH_POS_INTEGRATION_FAILURE',

  FETCH_PRINTER_INTEGRATION_START: 'FETCH_PRINTER_INTEGRATION_START',
  FETCH_PRINTER_INTEGRATION_SUCCESS: 'FETCH_PRINTER_INTEGRATION_SUCCESS',
  FETCH_PRINTER_INTEGRATION_FAILURE: 'FETCH_PRINTER_INTEGRATION_FAILURE',

  CONNECT_STRIPE_REQUEST: 'CONNECT_STRIPE_REQUEST',
  CONNECT_STRIPE_SUCCESS: 'CONNECT_STRIPE_SUCCESS',
  CONNECT_STRIPE_FAILURE: 'CONNECT_STRIPE_FAILURE',

  CREATE_CHECKLIST_STATUS: 'CREATE_CHECKLIST_STATUS',
  CREATE_CHECKLIST_STATUS_SUCCESS: 'CREATE_CHECKLIST_STATUS_SUCCESS',
  CREATE_CHECKLIST_STATUS_FAILURE: 'CREATE_CHECKLIST_STATUS_FAILURE',

  FETCH_CHECKLIST_STATUS: 'FETCH_CHECKLIST_STATUS',
  FETCH_CHECKLIST_STATUS_SUCCESS: 'FETCH_CHECKLIST_STATUS_SUCCESS',
  FETCH_CHECKLIST_STATUS_FAILURE: 'FETCH_CHECKLIST_STATUS_FAILURE',

  UPDATE_CHECKLIST_STATUS: 'UPDATE_CHECKLIST_STATUS',
  UPDATE_CHECKLIST_STATUS_SUCCESS: 'UPDATE_CHECKLIST_STATUS_SUCCESS',
  UPDATE_CHECKLIST_STATUS_FAILURE: 'UPDATE_CHECKLIST_STATUS_FAILURE',

  FETCH_REFERRAL_LIST: 'FETCH_REFERRAL_LIST',
  FETCH_REFERRAL_LIST_SUCCESS: 'FETCH_REFERRAL_LIST_SUCCESS',
  FETCH_REFERRAL_LIST_FAILURE: 'FETCH_REFERRAL_LIST_FAILURE',

  CREATE_REFERRAL: 'CREATE_REFERRAL',
  EDIT_REFERRAL: 'EDIT_REFERRAL',
  DELETE_REFERRAL: 'DELETE_REFERRAL',

  CREATE_REFERRAL_RESTAURANT: 'CREATE_REFERRAL_RESTAURANT',

  FETCH_SUBSCRIPTION_STATUS: 'FETCH_SUBSCRIPTION_STATUS',
  FETCH_SUBSCRIPTION_STATUS_SUCCESS: 'FETCH_SUBSCRIPTION_STATUS_SUCCESS',
  FETCH_SUBSCRIPTION_STATUS_FAILURE: 'FETCH_SUBSCRIPTION_STATUS_FAILURE',

  FETCH_GUARD_RAIL: 'FETCH_GUARD_RAIL',
  FETCH_FETCH_GUARD_RAIL_STATUS_SUCCESS:
    'FETCH_FETCH_GUARD_RAIL_STATUS_SUCCESS',
  FETCH_GUARD_RAIL_STATUS_SUCCESS: 'FETCH_GUARD_RAIL_STATUS_SUCCESS',

  fetchRKeeperSettingsStart: (userId) => ({
    type: actions.FETCH_RKEEPER_SETTINGS_START,
    payload: userId,
  }),
  fetchRKeeperSettingsSuccess: (userId, rKeeperSettings) => ({
    type: actions.FETCH_RKEEPER_SETTINGS_SUCCESS,
    payload: {
      userId,
      rKeeperSettings,
    },
  }),
  fetchRKeeperSettingsFailure: (error) => ({
    type: actions.FETCH_RKEEPER_SETTINGS_FAILURE,
    payload: error,
  }),

  createRKeeperSettingsStart: (restaurantId, rKeeperSettings) => ({
    type: actions.CREATE_RKEEPER_SETTINGS_START,
    payload: {
      ...rKeeperSettings,
      restaurant_id: restaurantId,
    },
  }),
  submitRKeeperSettingsStart: (restaurantId, rKeeperSettings) => ({
    type: actions.SUBMIT_RKEEPER_SETTINGS_START,
    payload: {
      ...rKeeperSettings,
      restaurant_id: restaurantId,
    },
  }),
  submitRKeeperSettingsSuccess: (userId, rKeeperSettings) => ({
    type: actions.SUBMIT_RKEEPER_SETTINGS_SUCCESS,
    payload: {
      userId,
      rKeeperSettings,
    },
  }),
  submitRKeeperSettingsFailure: (error) => ({
    type: actions.SUBMIT_RKEEPER_SETTINGS_FAILURE,
    payload: error,
  }),

  fetchPartnerSettingsStart: (userId) => ({
    type: actions.FETCH_PARTNER_SETTINGS_START,
    payload: userId,
  }),
  fetchPartnerSettingsSuccess: (userId, partnerSettings) => ({
    type: actions.FETCH_PARTNER_SETTINGS_SUCCESS,
    payload: {
      userId,
      partnerSettings,
    },
  }),
  fetchPartnerSettingsFailure: (error) => ({
    type: actions.FETCH_PARTNER_SETTINGS_FAILURE,
    payload: error,
  }),

  submitPartnerSettingsStart: (data) => ({
    type: actions.SUBMIT_PARTNER_SETTINGS_START,
    payload: { data },
  }),
  submitPartnerSettingsSuccess: (data) => ({
    type: actions.SUBMIT_PARTNER_SETTINGS_SUCCESS,
    payload: { data },
  }),
  submitPartnerSettingsFailure: (error) => ({
    type: actions.SUBMIT_PARTNER_SETTINGS_FAILURE,
    payload: { error },
  }),

  submitPartnerPlanStart: (userId, data, stepData, history) => ({
    type: actions.SUBMIT_PARTNER_PLAN_START,
    payload: { userId, data, stepData, history },
  }),
  submitPartnerPlanSuccess: (userId, data) => ({
    type: actions.SUBMIT_PARTNER_PLAN_SUCCESS,
    payload: { userId, data },
  }),
  submitPartnerPlanFailure: (error) => ({
    type: actions.SUBMIT_PARTNER_PLAN_FAILURE,
    payload: { error },
  }),

  submitCreateSiteStart: (userId, data, stepData, history) => ({
    type: actions.SUBMIT_CREATE_SITE_START,
    payload: { userId, data, stepData, history },
  }),

  submitCreateSiteSuccess: (userId, data) => ({
    type: actions.SUBMIT_CREATE_SITE_SUCCESS,
    payload: { userId, data },
  }),

  submitCreateSiteFailure: (error) => ({
    type: actions.SUBMIT_CREATE_SITE_FAILURE,
    payload: { error },
  }),

  submitCreateMenuStart: (userId, data, stepData, history) => ({
    type: actions.SUBMIT_CREATE_MENU_START,
    payload: { userId, data, stepData, history },
  }),

  submitCreateMenuSuccess: (userId, data) => ({
    type: actions.SUBMIT_CREATE_MENU_SUCCESS,
    payload: { userId, data },
  }),

  submitCreateMenuFailure: (error) => ({
    type: actions.SUBMIT_CREATE_MENU_FAILURE,
    payload: { error },
  }),

  fetchBrandSettingsStart: (userId, restaurantId) => ({
    type: actions.FETCH_BRAND_SETTINGS_START,
    payload: { userId, restaurantId },
  }),

  fetchBrandSettingsSuccess: (userId, brandSettings) => ({
    type: actions.FETCH_BRAND_SETTINGS_SUCCESS,
    payload: { userId, brandSettings },
  }),

  submitFillBrandStart: (userId, data, stepData, history) => ({
    type: actions.SUBMIT_FILL_BRAND_START,
    payload: { userId, data, stepData, history },
  }),

  submitFillBrandSuccess: (userId, data) => ({
    type: actions.SUBMIT_FILL_BRAND_SUCCESS,
    payload: { userId, data },
  }),
  fetchAutomationSettingsStart: (restaurantId) => {
    return {
      type: actions.FETCH_AUTOMATION_SETTINGS_START,
      payload: { restaurantId },
    };
  },
  fetchAutomationSettingsSuccess: (restaurantId, automationSettings) => ({
    type: actions.FETCH_AUTOMATION_SETTINGS_SUCCESS,
    payload: { restaurantId, automationSettings },
  }),
  fetchAutomationSettingsFailure: (restaurantId) => ({
    type: actions.FETCH_BRAND_SETTINGS_FAILURE,
    payload: { restaurantId },
  }),
  submitAutomationSettingsStart: (data, restaurantId) => ({
    type: actions.SUBMIT_AUTOMATION_SETTINGS_START,
    payload: { data, restaurantId },
  }),
  submitAutomationSettingsSuccess: (data) => ({
    type: actions.SUBMIT_AUTOMATION_SETTINGS_SUCCESS,
    payload: { data },
  }),
  submitAutomationSettingsFailure: (error) => ({
    type: actions.SUBMIT_AUTOMATION_SETTINGS_FAILURE,
    payload: { error },
  }),

  submitSiteAddressStart: (userId, data, stepData, wlData, history) => ({
    type: actions.SUBMIT_SITE_ADDRESS_START,
    payload: { userId, data, stepData, wlData, history },
  }),
  submitSiteAddressSuccess: (userId, data) => ({
    type: actions.SUBMIT_SITE_ADDRESS_SUCCESS,
    payload: { userId, data },
  }),
  submitSiteAddressFailure: (error) => ({
    type: actions.SUBMIT_SITE_ADDRESS_FAILURE,
    payload: { error },
  }),

  submitSiteAddressDomainStart: (wlData) => ({
    type: actions.SUBMIT_SITE_ADDRESS_DOMAIN_START,
    payload: { wlData },
  }),
  submitSiteAddressDomainSuccess: (userId, data) => ({
    type: actions.SUBMIT_SITE_ADDRESS_DOMAIN_SUCCESS,
    payload: { userId, data },
  }),
  submitSiteAddressDomainFailure: (error) => ({
    type: actions.SUBMIT_SITE_ADDRESS_DOMAIN_FAILURE,
    payload: { error },
  }),

  fetchIikoSettingsStart: (userId) => ({
    type: actions.FETCH_IIKO_SETTINGS_START,
    payload: userId,
  }),
  fetchIikoSettingsSuccess: (restaurantId, iikoSettings) => ({
    type: actions.FETCH_IIKO_SETTINGS_SUCCESS,
    payload: {
      restaurantId,
      iikoSettings,
    },
  }),
  fetchIikoSettingsFailure: (error) => ({
    type: actions.FETCH_IIKO_SETTINGS_FAILURE,
    payload: error,
  }),

  createIikoSettingsStart: (restaurantId, iikoSettings) => ({
    type: actions.CREATE_IIKO_SETTINGS_START,
    payload: {
      ...iikoSettings,
      restaurant_id: restaurantId,
    },
  }),
  submitIikoSettingsStart: (restaurantId, iikoSettings) => ({
    type: actions.SUBMIT_IIKO_SETTINGS_START,
    payload: {
      ...iikoSettings,
      restaurant_id: restaurantId,
    },
  }),
  submitIikoSettingsSuccess: (userId, iikoSettings) => ({
    type: actions.SUBMIT_IIKO_SETTINGS_SUCCESS,
    payload: {
      userId,
      iikoSettings,
    },
  }),
  submitIikoSettingsFailure: (error) => ({
    type: actions.SUBMIT_IIKO_SETTINGS_FAILURE,
    payload: error,
  }),

  fetchPOSIntegrationStart: (restaurantId) => ({
    type: actions.FETCH_POS_INTEGRATION_START,
    payload: restaurantId,
  }),
  fetchPOSIntegrationSuccess: (restaurantId, posIntegration) => ({
    type: actions.FETCH_POS_INTEGRATION_SUCCESS,
    payload: {
      restaurantId,
      posIntegration,
    },
  }),
  fetchPOSIntegrationFailure: (error) => ({
    type: actions.FETCH_POS_INTEGRATION_FAILURE,
    payload: error,
  }),

  fetchPrinterIntegrationStart: (restaurantId) => ({
    type: actions.FETCH_PRINTER_INTEGRATION_START,
    payload: restaurantId,
  }),
  fetchPrinterIntegrationSuccess: (restaurantId, printerIntegration) => ({
    type: actions.FETCH_PRINTER_INTEGRATION_SUCCESS,
    payload: {
      restaurantId,
      printerIntegration,
    },
  }),
  fetchPrinterIntegrationFailure: (error) => ({
    type: actions.FETCH_PRINTER_INTEGRATION_FAILURE,
    payload: error,
  }),
  fetchReferralList: () => ({
    type: actions.FETCH_REFERRAL_LIST,
  }),
  fetchReferralListSuccess: (data) => ({
    type: actions.FETCH_REFERRAL_LIST_SUCCESS,
    payload: data,
  }),
  fetchReferralListFailure: (data) => ({
    type: actions.FETCH_REFERRAL_LIST_FAILURE,
    payload: data,
  }),
  createReferral: (data) => ({
    type: actions.CREATE_REFERRAL,
    payload: data,
  }),
  editReferral: (data) => ({
    type: actions.EDIT_REFERRAL,
    payload: data,
  }),
  deleteReferral: (data) => ({
    type: actions.DELETE_REFERRAL,
    payload: data,
  }),
  createReferralRestaurant: (data) => ({
    type: actions.CREATE_REFERRAL_RESTAURANT,
    payload: data,
  }),
  fetchSubscriptionStatus: (data) => ({
    type: actions.FETCH_SUBSCRIPTION_STATUS,
    payload: data,
  }),
  fetchSubscriptionStatusSuccess: (data) => ({
    type: actions.FETCH_SUBSCRIPTION_STATUS_SUCCESS,
    payload: data,
  }),
  fetchSubscriptionStatusFailure: (data) => ({
    type: actions.FETCH_SUBSCRIPTION_STATUS_FAILURE,
    payload: data,
  }),
  fetchGuardRail: (data) => ({
    type: actions.FETCH_GUARD_RAIL,
    payload: data,
  }),
  fetchGuardRailStatusSuccess: (data) => ({
    type: actions.FETCH_FETCH_GUARD_RAIL_STATUS_SUCCESS,
    payload: data,
  }),
  fetchGuardRailStatusFailure: (data) => ({
    type: actions.FETCH_GUARD_RAIL_STATUS_SUCCESS,
    payload: data,
  }),
  createChecklistStatus: (data) => ({
    type: actions.CREATE_CHECKLIST_STATUS,
    payload: data,
  }),
  createChecklistStatusSuccess: (data) => ({
    type: actions.CREATE_CHECKLIST_STATUS_SUCCESS,
    payload: data,
  }),
  createChecklistStatusFailure: (data) => ({
    type: actions.CREATE_CHECKLIST_STATUS_FAILURE,
    payload: data,
  }),
  fetchChecklistStatus: (data) => ({
    type: actions.FETCH_CHECKLIST_STATUS,
    payload: data,
  }),
  fetchChecklistStatusSuccess: (data) => ({
    type: actions.FETCH_CHECKLIST_STATUS_SUCCESS,
    payload: data,
  }),
  fetchChecklistStatusFailure: (data) => ({
    type: actions.FETCH_CHECKLIST_STATUS_FAILURE,
    payload: data,
  }),
  connectStripeRequest: (data) => ({
    type: actions.CONNECT_STRIPE_REQUEST,
    payload: data,
  }),
  connectStripeSuccess: (url) => ({
    type: actions.CONNECT_STRIPE_SUCCESS,
    payload: url,
  }),
  connectStripeFailure: (error) => ({
    type: actions.CONNECT_STRIPE_FAILURE,
    payload: error,
  }),
  updateChecklistStatus: (data) => ({
    type: actions.UPDATE_CHECKLIST_STATUS,
    payload: data,
  }),
  updateChecklistStatusSuccess: (data) => ({
    type: actions.UPDATE_CHECKLIST_STATUS_SUCCESS,
    payload: data,
  }),
  updateChecklistStatusFailure: (data) => ({
    type: actions.UPDATE_CHECKLIST_STATUS_FAILURE,
    payload: data,
  }),
};
export default actions;
