const localEatsActions = {
  FETCH_MARKETPLACE_DATA_START: 'FETCH_MARKETPLACE_DATA_START',
  FETCH_MARKETPLACE_DATA_SUCCESS: 'FETCH_MARKETPLACE_DATA_SUCCESS',
  FETCH_MARKETPLACE_DATA_FAILURE: 'FETCH_MARKETPLACE_DATA_FAILURE',

  fetchMarketplaceDataStart: (marketplaceCode) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_START,
    payload: marketplaceCode,
  }),
  fetchMarketplaceDataSuccess: (data) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_SUCCESS,
    payload: data,
  }),
  fetchMarketplaceDataFailure: (error) => ({
    type: localEatsActions.FETCH_MARKETPLACE_DATA_FAILURE,
    payload: error,
  }),
};

export default localEatsActions;
