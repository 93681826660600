import { notification } from 'antd';

let notificationApi = undefined;

const setApi = (ctxApi) => {
  notificationApi = ctxApi;
};

const createNotification = (type, message, description, duration) => {
  if (notificationApi) {
    setTimeout(
      () =>
        notificationApi[type]({
          message,
          description,
          duration,
          style: { borderRadius: '8px' },
        }),
      0
    );
    return;
  }
  notification[type]({
    message,
    description,
    duration,
    style: { borderRadius: '8px' },
  });
};
export default createNotification;
export { setApi, notificationApi };
