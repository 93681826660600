// saga.js
import { all, takeEvery, put, call } from 'redux-saga/effects';
import trainingActions from './actions';
import siteConfig from '@iso/config/site.config';

export const onTrainingRequest = async (brandId) =>
  await fetch(
    `${siteConfig.apiUrl}/training/` +
      localStorage.getItem('id') +
      `/` +
      brandId
  )
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onCreateTrainingVideoRequest = async (data, brandId) =>
  await fetch(`${siteConfig.apiUrl}/trainingVideo/` + brandId, {
    crossDomain: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    });

export const onDeleteTrainingVideoRequest = async (data, brandId) =>
  await fetch(`${siteConfig.apiUrl}/deleteTrainingVideo/` + brandId, {
    crossDomain: true,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const onUploadTrainingFileRequest = async (data, brandId) =>
  await fetch(`${siteConfig.apiUrl}/updateTrainingFile/` + brandId, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    });

export const onUploadTrainingVideoRequest = async (data, brandId) =>
  await fetch(`${siteConfig.apiUrl}/updateTrainingVideo/` + brandId, {
    crossDomain: true,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      return json;
    });

export const onUploadFile = async (file) =>
  await fetch(`${siteConfig.apiUrl}/upload`, {
    crossDomain: true,
    method: 'POST',
    mode: 'cors',
    body: file,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

function* fetchBrandsProfileDataEffect({ payload: { brandId } }) {
  try {
    let profileBrand = yield call(onTrainingRequest, brandId);
    yield put(trainingActions.fetchBrandDataSuccess(profileBrand));
  } catch (error) {
    yield put(trainingActions.fetchBrandDataFailure(error));
  }
}

function* fetchCreateTrainingVideo({ payload, brandId }) {
  try {
    console.log('fetchCreateTrainingVideo', { payload, brandId });
    let trainingData = yield call(
      onCreateTrainingVideoRequest,
      payload,
      brandId
    );

    yield put(trainingActions.fetchCreateTrainingVideoSuccess(trainingData));
  } catch (error) {
    yield put(trainingActions.fetchCreateTrainingVideoFailure(error));
  }
}

function* fetchDeleteTrainingVideo({ payload, brandId }) {
  try {
    console.log('fetchDeleteTrainingVideo', { payload, brandId });
    let trainingData = yield call(
      onDeleteTrainingVideoRequest,
      payload,
      brandId
    );

    yield put(trainingActions.fetchDeleteTrainingVideoSuccess(trainingData));
  } catch (error) {
    yield put(trainingActions.fetchDeleteTrainingVideoFailure(error));
  }
}

function* fetchUpdateTrainingFile({ payload, brandId }) {
  try {
    console.log('fetchUpdateTrainingFile', { payload, brandId });
    let trainingData = yield call(
      onUploadTrainingFileRequest,
      payload,
      brandId
    );

    yield put(trainingActions.updateTrainingFileSuccess(trainingData));
  } catch (error) {
    yield put(trainingActions.updateTrainingFileFailure(error));
  }
}

function* fetchUpdateTrainingVideo({ payload, brandId }) {
  try {
    console.log('fetchUpdateTrainingVideo', { payload, brandId });
    let trainingData = yield call(
      onUploadTrainingVideoRequest,
      payload,
      brandId
    );

    yield put(trainingActions.updateTrainingFileSuccess(trainingData));
  } catch (error) {
    yield put(trainingActions.updateTrainingFileFailure(error));
  }
}

function* fetchUploadFile({ payload: { file, fileType }, key, brandId }) {
  try {
    let fileLink = yield call(onUploadFile, file);
    console.log(fileLink);

    yield put(
      trainingActions.uploadFileSuccess(
        fileLink.fileLinks,
        fileType,
        key,
        brandId
      )
    );
  } catch (error) {
    yield put(trainingActions.uploadFileFailure(error));
  }
}

export default function* brandSaga() {
  yield all([
    takeEvery(
      trainingActions.FETCH_BRAND_DATA_START,
      fetchBrandsProfileDataEffect
    ),
    takeEvery(
      trainingActions.FETCH_UPDATE_TRAINING_FILE,
      fetchUpdateTrainingFile
    ),
    takeEvery(
      trainingActions.FETCH_UPDATE_TRAINING_VIDEO,
      fetchUpdateTrainingVideo
    ),
    takeEvery(trainingActions.CREATE_TRAINING_VIDEO, fetchCreateTrainingVideo),
    takeEvery(trainingActions.DELETE_TRAINING_VIDEO, fetchDeleteTrainingVideo),
    takeEvery(trainingActions.UPLOAD_FILE, fetchUploadFile),
  ]);
}
