import clone from 'clone';
import restaurantActions from './actions';

const INITIAL_DATA = {
  status: null,
  allRestaurants: null,
  allRestaurantsWL: null,
  restaurantTables: null,
  restaurantWaiters: null,
  restaurantManagers: null,
  restaurantBrands: null,
  restaurantRating: null,
  data: null,
  loading: true,
  loadingWl: true,
  error: null,
  unsubscribeStatus: null,
  // restaurantBrands: {
  //   connected: [],
  //   all: [],
  // },
};
export default function restaurantReducer(state = INITIAL_DATA, action) {
  switch (action.type) {
    case restaurantActions.FETCH_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        allRestaurants: action.payload,
      };
    }
    case restaurantActions.GET_RESTAURANT_STATE_SUCCESS: {
      return {
        ...state,
        status: action.payload?.items?.length === 0,
      };
    }

    case restaurantActions.SET_RESTAURANT_UNBLOCK_SUCCESS: {
      const st = action.payload;
      return {
        ...state,
        loading: false,
        data: st,
        status: true,
      };
    }

    case restaurantActions.SET_RESTAURANT_BLOCK_SUCCESS: {
      const st = action.payload;
      return {
        ...state,
        loading: false,
        data: st,
        status: false,
      };
    }

    case restaurantActions.FETCH_RESTAURANTS_WL_SUCCESS: {
      return {
        ...state,
        loadingWl: false,
        allRestaurantsWL: action.payload,
      };
    }

    case restaurantActions.FETCH_RESTAURANT_TABLES_SUCCESS:
      return {
        ...state,
        restaurantTables: action.payload,
      };
    case restaurantActions.FETCH_ADD_RESTAURANT_TABLES_SUCCESS: {
      const table = action.payload;
      const tables = clone(state.restaurantTables);
      const pIdx = tables.findIndex((p) => p.id === table.id);

      if (pIdx === -1) {
        tables.push(table);
      } else {
        tables[pIdx] = table;
      }
      return {
        ...state,
        restaurantTables: tables,
      };
    }

    case restaurantActions.FETCH_RESTAURANT_WAITERS_SUCCESS:
      return {
        ...state,
        restaurantWaiters: action.payload,
      };
    case restaurantActions.FETCH_ADD_RESTAURANT_WAITER_SUCCESS: {
      const waiter = action.payload;
      const waiters = clone(state.restaurantWaiters);
      const pIdx = waiters.findIndex((p) => p.id === waiter.id);

      if (pIdx === -1) {
        waiters.push(waiter);
      } else {
        waiters[pIdx] = waiter;
      }
      return {
        ...state,
        restaurantWaiters: waiters,
      };
    }

    case restaurantActions.FETCH_RESTAURANT_RATING_SUCCESS:
      return {
        ...state,
        restaurantRating: action.payload,
      };

    case restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_SUCCESS: {
      const { tableId } = action.payload;
      const tables = state.restaurantTables.slice();
      const pIdx = tables.findIndex((p) => p.id === parseInt(tableId));

      if (pIdx !== -1) {
        tables.splice(pIdx, 1);
      }
      return {
        ...state,
        restaurantTables: tables,
      };
    }
    case restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_SUCCESS: {
      const { waiterId } = action.payload;
      console.log(waiterId);
      const waiters = state.restaurantWaiters.slice();
      const pIdx = waiters.findIndex((p) => p.id === parseInt(waiterId));

      if (pIdx !== -1) {
        waiters.splice(pIdx, 1);
      }
      return {
        ...state,
        restaurantWaiters: waiters,
      };
    }
    case restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_SUCCESS: {
      const { managerId } = action.payload;
      const tables = state.restaurantManagers.slice();
      const pIdx = tables.findIndex((p) => p.id === parseInt(managerId));

      if (pIdx !== -1) {
        tables.splice(pIdx, 1);
      }
      return {
        ...state,
        restaurantManagers: tables,
      };
    }
    case restaurantActions.FETCH_RESTAURANT_MANAGERS_SUCCESS:
      return {
        ...state,
        restaurantManagers: action.payload,
      };
    case restaurantActions.FETCH_ADD_RESTAURANT_MANAGERS_SUCCESS: {
      const table = action.payload;
      const tables = clone(state.restaurantManagers);
      const pIdx = tables.findIndex((p) => p.id === table.id);

      if (pIdx === -1) {
        tables.push(table);
      } else {
        tables[pIdx] = table;
      }
      return {
        ...state,
        restaurantManagers: tables,
      };
    }
    case restaurantActions.FETCH_RESTAURANT_BRANDS_SUCCESS:
      return {
        ...state,
        restaurantBrands: action.payload,
      };
    case restaurantActions.UNSUBSCRIBE_CUSTOMER_SUCCESS:
      return {
        ...state,
        unsubscribeStatus: action.payload,
      };
    case restaurantActions.FETCH_RESTAURANTS_FAILURE:
    case restaurantActions.FETCH_RESTAURANTS_WL_FAILURE:
    case restaurantActions.GET_RESTAURANT_STATE_FAILURE:
    case restaurantActions.SET_RESTAURANT_UNBLOCK_FAILURE:
    case restaurantActions.SET_RESTAURANT_BLOCK_FAILURE:
    case restaurantActions.FETCH_RESTAURANT_TABLES_FAILURE:
    case restaurantActions.FETCH_ADD_RESTAURANT_TABLES_FAILURE:
    case restaurantActions.FETCH_RESTAURANT_WAITERS_FAILURE:
    case restaurantActions.FETCH_ADD_WAITER_TO_TABLE_FAILURE:
    case restaurantActions.FETCH_ADD_RESTAURANT_WAITER_FAILURE:
    case restaurantActions.FETCH_DELETE_RESTAURANT_TABLE_FAILURE:
    case restaurantActions.FETCH_RESTAURANT_RATING_FAILURE:
    case restaurantActions.FETCH_RESTAURANT_BRANDS_FAILURE:
    case restaurantActions.FETCH_DELETE_RESTAURANT_WAITER_FAILURE:
    case restaurantActions.FETCH_ADD_MANAGER_TO_TABLE_FAILURE:
    case restaurantActions.FETCH_DELETE_RESTAURANT_MANAGER_FAILURE:
    case restaurantActions.FETCH_DELETE_MANAGER_FROM_TABLE_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
}
